import firebase from 'firebase';

firebase.initializeApp({
  apiKey: 'AIzaSyCVO-9pOPd5HGxTXgdH_NqnKLI9dH9pZ8w',
  authDomain: 'rotesmartservices.firebaseapp.com',
  databaseURL: 'https://rotesmartservices-default-rtdb.firebaseio.com',
  projectId: 'rotesmartservices',
  storageBucket: 'rotesmartservices.appspot.com',
  messagingSenderId: '83138703061',
  appId: '1:83138703061:web:ab2e2db1991eb07a367c5c',
});

const FIREBASE = firebase;

export default FIREBASE;
