import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { DELETE_EMERGENCY, GET_TOKEN, API_TIMEOUT, GET_INSTANSI } from 'utils';

import swal from 'sweetalert';
import axios from 'axios';
import { logOutUser } from 'actions/AuthAction';

import jwt_decode from 'jwt-decode';
import { dateTime } from 'utils';
import { getLogApi } from 'actions/LogApiAction';
export default function ListLogApi() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListLogApiLoading, getListLogApiResult, getListLogApiError } =
    useSelector((state) => state.LogApiReducer);

  // auth

  const [token, setToken] = useState('');
  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');
  const [accessToken, setAccessToken] = useState('');
  const [hariIni, sethariIni] = useState('');
  const [labelBulan, setLabelBulan] = useState('');
  const [bulan, setBulan] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [tahun, setTahun] = useState('');
  const [listInstansi, setListInstansi] = useState([]);
  const [instansi, setInstansi] = useState('ALL');

  const [statusUser, setStatusUser] = useState('ASN');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAccessToken(response.data.accessToken);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getTanggal = () => {
    var date = new Date();
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = '0' + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + '-' + month + '-' + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);

    setLabelBulan(month);

    setTahun(year);

    if (month.length == 1) {
      month = '0' + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = '0' + day;
    }
    var tanggalKu = year + '-' + month + '-' + day;

    setTanggal(tanggalKu);
  };

  //dari smart service

  useEffect(() => {
    getTanggal();

    //refreshToken();
    dispatch(getLogApi());
  }, []);

  useEffect(() => {
    //  dispatch(getUserStatusRegister({ statusRegister: statusUser }));
  }, [statusUser]);

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader></CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Status</th>
                    <th>Method</th>
                    <th>API Endpoint</th>
                    <th>Nama Aplikasi</th>
                    <th>Type Aplikasi</th>
                    <th>Waktu</th>
                  </tr>
                </thead>

                <tbody>
                  {getListLogApiResult ? (
                    Object.keys(getListLogApiResult).map((key, index) => (
                      <tr key={key}>
                        <td>
                          <div
                            style={{
                              backgroundColor:
                                getListLogApiResult[key].status == '200'
                                  ? 'green'
                                  : 'red',
                              textAlign: 'center',
                              borderRadius: 10,
                              padding: 5,
                            }}
                          >
                            <text style={{ color: 'white' }}>
                              {getListLogApiResult[key].status}
                            </text>
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {getListLogApiResult[key].method}
                        </td>
                        <td>{getListLogApiResult[key].api} </td>
                        <td>{getListLogApiResult[key].aplikasi} </td>
                        <td>{getListLogApiResult[key].tipe} </td>
                        <td>{getListLogApiResult[key].createdAt} </td>
                      </tr>
                    ))
                  ) : getListLogApiLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' /> Loading....
                      </td>
                    </tr>
                  ) : getListLogApiError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListLogApiError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
