import { GET_LIST_VIDEO } from '../../actions/VideoAction';

const initialState = {
  getListVideoLoading: false,
  getListVideoResult: false,
  getListVideoError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_VIDEO:
      return {
        ...state,
        getListVideoLoading: action.payload.loading,
        getListVideoResult: action.payload.data,
        getListVideoError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
