import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { getAdmins } from '../../actions/AdminAction';
import { DELETE_ADMIN, GET_TOKEN } from 'utils';

import swal from 'sweetalert';
import axios from 'axios';
import { logOutUser } from 'actions/AuthAction';
import jwt_decode from 'jwt-decode';

export default function ListAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListAdminError, getListAdminLoading, getListAdminResult } =
    useSelector((state) => state.AdminReducer);

  // componentDidUpdate(prevProps) {
  //   const { deleteJerseyResult } = this.props;

  //   if (
  //     deleteJerseyResult &&
  //     prevProps.deleteJerseyResult !== deleteJerseyResult
  //   ) {
  //     swal('Sukses!', deleteJerseyResult, 'success');
  //     this.props.dispatch(getListJersey());
  //   }
  // }

  // auth

  const [token, setToken] = useState('');

  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');
  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setAccessToken(response.data.accessToken);
      setUsername(decoded.username);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  useEffect(() => {
    dispatch(getAdmins());
    refreshToken();
  }, []);

  const removeData = async (id) => {
    try {
      await axios.delete(DELETE_ADMIN + `${id}`, {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      swal('Sukses!', 'Admin berhasil dihapus', 'success');
      history.push('/admin');
      history.push('/admin/user');
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>List Admin</CardTitle>
              <Link
                to={'/admin/user/tambah/' + idAdmin}
                className='btn btn-primary float-right'
              >
                Tambah Admin
              </Link>
            </CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Id</th>
                    <th>Foto</th>
                    <th>Nama</th>
                    <th>Instansi</th>
                    <th>Status</th>

                    <th>akses</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {getListAdminResult ? (
                    Object.keys(getListAdminResult).map((key) => (
                      <tr key={key}>
                        <td>{getListAdminResult[key].id}</td>
                        <td>
                          <img
                            src={getListAdminResult[key].photo}
                            width='100'
                            alt={getListAdminResult[key].nama}
                          />
                        </td>
                        <td>{getListAdminResult[key].nama}</td>
                        <td>{getListAdminResult[key].instansi}</td>
                        <td>{getListAdminResult[key].status}</td>

                        <td>{getListAdminResult[key].akses} </td>
                        <td>
                          <Link
                            className='btn btn-warning'
                            to={
                              '/admin/user/edit/' +
                              getListAdminResult[key].id +
                              ',' +
                              idAdmin
                            }
                          >
                            <i className='nc-icon nc-ruler-pencil'></i> Edit
                          </Link>
                        </td>
                        <td>
                          <Button
                            color='danger'
                            className='ml-2'
                            onClick={() =>
                              removeData(getListAdminResult[key].id)
                            }
                          >
                            <i className='nc-icon nc-basket'></i> Hapus
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : getListAdminLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : getListAdminError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListAdminError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
