import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import axios from 'axios';
import { POST_ADMIN, GET_INSTANSI, GET_TOKEN } from 'utils';

export default function TambahAdmin() {
  const history = useHistory();

  const { id } = useParams();

  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [instansi, setInstansi] = useState('');
  const [status, setStatus] = useState('');
  const [akses, setAkses] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState('');
  const [listInstansi, setListInstansi] = useState([]);
  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [saveLoading, setSaveLoading] = useState(false);
  const layanans = [
    'Dashboard',
    'Berita',
    'Daftar Hadir',
    'Produk',
    'Toko',
    'Pesanan Masuk',
    'Notifikasi',
    'Destinasi',
    'Fasilitas Destinasi',
    'Banner',
    'Notifikasi',
    'Layanan',
    'Users',
    'Emergency Contact',
    'Sampah',
    'Forum RSS',
    'Admin',
    'Pasar Ternak',
    'Daftar Hadir',
    'Smart User',
    'ASN User',
  ];

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        // history.push('/admin/user');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
    getListInstansi();
  }, []);

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
  }

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (nama && username && status && checked && password) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const data = {};

      data.nama = nama;
      data.username = username;
      data.instansi = instansi;
      data.status = status;
      data.akses = checked.toString();
      data.password = password;
      data.idAdmin = id;

      const response = await axios.post(POST_ADMIN, data, {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Admin berhasil ditambahkan', 'success');
        setSaveLoading(false);
        history.push('/admin/user');
      }
    } else {
      setSaveLoading(false);
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
  };

  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/admin' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tambah Admin</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Hak akses kepada layanan</label>
                          <FormGroup check>
                            {layanans.map((layanan, index) => (
                              <Label key={index} check className='mr-2'>
                                <Input
                                  type='checkbox'
                                  value={layanan}
                                  onChange={(event) => changeCheck(event)}
                                />
                                {layanan}
                                <span className='form-check-sign'>
                                  <span className='check'></span>
                                </span>
                              </Label>
                            ))}
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Status Admin</label>
                          <Input
                            type='select'
                            value={status}
                            name='berat'
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value={''}> -pilih-</option>
                            <option value={'admin'}> admin</option>
                            <option value={'super admin'}> super admin</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama</label>
                      <Input
                        type='text'
                        value={nama}
                        name='nama'
                        onChange={(event) => setNama(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>username</label>
                          <Input
                            type='text'
                            name='liga'
                            value={username}
                            onChange={(event) =>
                              setUsername(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            type='text'
                            value={password}
                            name='harga'
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <label>Instansi</label>
                          <Input
                            type='select'
                            value={instansi}
                            name='berat'
                            onChange={(event) =>
                              setInstansi(event.target.value)
                            }
                          >
                            <option value={''}> -pilih-</option>
                            {listInstansi.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Ready</label>
                          <Input type='select' name='ready' value=''>
                            <option value={true}>Ada</option>
                            <option value={false}>Kosong</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
