import { GET_LIST_EMERGENCY } from '../../actions/EmergencyAction';

const initialState = {
  getEmergencyLoading: false,
  getEmergencyResult: false,
  getEmergencyError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_EMERGENCY:
      return {
        ...state,
        getEmergencyLoading: action.payload.loading,
        getEmergencyResult: action.payload.data,
        getEmergencyError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
