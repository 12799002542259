import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';
import { GET_BERITA, GET_BERITAPORTAL, API_TIMEOUT } from '../utils/constant';

import axios from 'axios';

export const GET_LIST_BERITA = 'GET_LIST_BERITA';
export const GET_LIST_BERITA_PORTAL = 'GET_LIST_BERITA_PORTAL';

export const getBerita = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_BERITA);

    axios({
      method: 'get',
      url: GET_BERITA,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_BERITA, 'error');
        } else {
          //BERHASIL

          dispatchSuccess(
            dispatch,
            GET_LIST_BERITA,
            response.data.data ? response.data.data : []
          );
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_BERITA, 'error');
        alert(error);
      });
  };
};

export const getBeritaPortal = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_BERITA_PORTAL);
    console.log('masuk action');

    axios({
      method: 'get',
      url: GET_BERITAPORTAL,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_BERITA_PORTAL, 'error');
        } else {
          //BERHASIL

          dispatchSuccess(
            dispatch,
            GET_LIST_BERITA_PORTAL,
            response.data.data ? response.data.data : []
          );
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_BERITA_PORTAL, 'error');
        alert(error);
      });
  };
};
