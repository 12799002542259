import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './reducers/store';

import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import AdminLayout from 'layouts/Admin.js';
import { Login } from './views';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
        <Route path='/login' component={Login} exact />

        <Redirect from='/' to='/login' />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
