import {
  GET_LIST_BERITA,
  GET_LIST_BERITA_PORTAL,
} from '../../actions/BeritaAction';

const initialState = {
  getListBeritaLoading: false,
  getListBeritaResult: false,
  getListBeritaError: false,

  getBeritaPortalLoading: false,
  getBeritaPortalResult: false,
  getBeritaPortalError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_BERITA:
      return {
        ...state,
        getListBeritaLoading: action.payload.loading,
        getListBeritaResult: action.payload.data,
        getListBeritaError: action.payload.errorMessage,
      };

    case GET_LIST_BERITA_PORTAL:
      return {
        ...state,
        getBeritaPortalLoading: action.payload.loading,
        getBeritaPortalResult: action.payload.data,
        getBeritaPortalError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
