import {
  GET_USER,
  GET_LIST_USER,
  GET_USER_EMAIL,
} from '../../actions/GetUserAction';

const initialState = {
  getUserLoading: false,
  getUserResult: false,
  getUserError: false,

  getListLoading: false,
  getListUserResult: false,
  getListUserError: false,

  getUserEmailLoading: false,
  getUserEmailResult: false,
  getUserEmailError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        getUserLoading: action.payload.loading,
        getUserResult: action.payload.data,
        getUserError: action.payload.errorMessage,
      };

    case GET_LIST_USER:
      return {
        ...state,
        getListUserLoading: action.payload.loading,
        getListUserResult: action.payload.data,
        getListUserError: action.payload.errorMessage,
      };

    case GET_USER_EMAIL:
      return {
        ...state,
        getUserEmailLoading: action.payload.loading,
        getUserEmailResult: action.payload.data,
        getUserEmailError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
