import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import axios from 'axios';
import { POST_BERITA, API_TIMEOUT, GET_TOKEN } from 'utils';

export default function TambahBerita(props) {
  const history = useHistory();
  const { id } = useParams();

  const [gambar, setGambar] = useState('');
  const [gambar2, setGambar2] = useState('');
  const [isi, setIsi] = useState('');
  const [judul, setJudul] = useState('');
  const [kategori, setKategori] = useState('');
  const [penulis, setPenulis] = useState('');
  const [status, setStatus] = useState('');
  const [sumber, setSumber] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [urlFile2, setUrlFile2] = useState(DefaultImage);
  const [saveLoading, setSaveLoading] = useState(false);
  const [latest_status, setLatest_status] = useState('');

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
  }

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
    setGambar2(e.target.files[0]);
  }

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/berita');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (
      isi &&
      judul &&
      kategori &&
      sumber &&
      penulis &&
      status &&
      tanggal &&
      gambar &&
      gambar2
    ) {
      const formData = new FormData();
      formData.append('photo', gambar);
      formData.append('photo', gambar2);

      formData.append('isi', isi);
      formData.append('judul', judul);
      formData.append('kategori', kategori);
      formData.append('penulis', penulis);
      formData.append('status', status);
      formData.append('sumber', sumber);
      formData.append('tanggal', tanggal);
      formData.append('idAdmin', id);

      await axios({
        method: 'post',
        url: POST_BERITA,
        timeout: API_TIMEOUT,
        credentials: 'include',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            swal('Failed', 'ada kesalahan pada server', 'error');
          } else {
            //BERHASIL
            swal('Success', 'Berita berhasil diposting', 'success');
            history.push('/admin/berita');
          }
        })
        .catch((error) => {
          // ERROR
          swal('Failed', 'Ada kesalahan pada server', 'error');
        });
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/berita' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tambah Berita</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Berita (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={urlFile2}
                          width='300'
                          alt='Foto Berita (dua)'
                        />
                        <FormGroup>
                          <label>Foto Berita (dua)</label>
                          <Input
                            type='file'
                            name='image2'
                            accept={'image/*'}
                            onChange={(event) => onChange2(event, 'imageToDB2')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Judul Berita</label>
                      <Input
                        type='text'
                        value={judul}
                        name='nama'
                        onChange={(event) => setJudul(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Kategori</label>
                          <Input
                            type='select'
                            name='liga'
                            value={kategori}
                            onChange={(event) =>
                              setKategori(event.target.value)
                            }
                          >
                            <option value=''>--Pilih--</option>
                            <option value='Kepala Daerah'>Kepala Daerah</option>
                            <option value='Lintas OPD'>Lintas OPD</option>
                            <option value='Publik'>Publik</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Penulis</label>
                          <Input
                            type='text'
                            value={penulis}
                            name='harga'
                            onChange={(event) => setPenulis(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status</label>
                          <Input
                            type='text'
                            value={status}
                            name='berat'
                            onChange={(event) => setStatus(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Sumber</label>
                          <Input
                            type='text'
                            value={sumber}
                            name='jenis'
                            onChange={(event) => setSumber(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Tanggal Berita</label>
                          <Input
                            type='date'
                            name='ready'
                            value={tanggal}
                            onChange={(event) => setTanggal(event.target.value)}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Isi Berita</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={isi}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setIsi(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
