import React, { Component, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import { GET_LAPORAN_DETAIL, GET_INSTANSI } from 'utils';
import axios from 'axios';
import { EDIT_LAPORAN, GET_TOKEN } from 'utils';

export default function EditAfada() {
  const { id } = useParams();
  const idKu = id.split(',');
  const history = useHistory();

  const [isi, setIsi] = useState('');
  const [judul, setJudul] = useState('');
  const [idUser, setIdUser] = useState('');
  const [instansi, setInstansi] = useState('');
  const [jenis, setJenis] = useState('');
  const [kategori, setKategori] = useState('');
  const [lokasi, setLokasi] = useState('');
  const [publikasi, setPublikasi] = useState('');
  const [sifat, setSifat] = useState('');
  const [status, setStatus] = useState('');
  const [tanggal, setTanggal] = useState('');

  const [saveLoading, setSaveLoading] = useState(false);

  const [listInstansi, setListInstansi] = useState([]);
  const [urlFile1, setUrlFile1] = useState('');
  const [urlFile2, setUrlFile2] = useState('');

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/afada');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
    getListInstansi();
    getDetailLaporan();
  }, [id]);

  const getDetailLaporan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_LAPORAN_DETAIL + `${idKu[0]}`);
      data1 = response.data.data[0];

      if (data1) {
        setUrlFile1(data1.foto.split(',')[0]);
        setUrlFile2(data1.pdf.split(',')[0]);
        setIsi(data1.isi);
        setJudul(data1.judul);
        setKategori(data1.kategori);
        setIdUser(data1.idUser);
        setInstansi(data1.instansi);
        setJenis(data1.jenis);
        setTanggal(data1.tanggal);
        setLokasi(data1.lokasi);
        setPublikasi(data1.publikasi);
        setSifat(data1.sifat);
        setStatus(data1.status);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
  }

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
  }

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.instansi;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (isi && judul && kategori && jenis && status) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();

      formData.append('kategori', kategori);
      formData.append('jenis', jenis);
      formData.append('status', status);
      formData.append('publikasi', publikasi);
      formData.append('sifat', sifat);
      formData.append('instansi', instansi);
      formData.append('idAdmin', idKu[1]);

      const response = await axios.patch(
        EDIT_LAPORAN + `${idKu[0]}`,
        formData,
        {
          credentials: 'include',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.success === 1) {
        swal('Success', 'Laporan berhasil diedit', 'success');
        setSaveLoading(false);
        history.push('/admin/afada');
      } else {
        setSaveLoading(false);
        swal('Failed', 'Penyimpanan gagal', 'error');
      }
    } else {
      setSaveLoading(false);
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/afada' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tindak Lanjut Laporan</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Laporan</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={urlFile2}
                          width='300'
                          alt='Foto Berita (dua)'
                        />
                        <FormGroup>
                          <label>Link Pdf file</label>
                          <a href={urlFile2}>
                            <label>File Pdf</label>
                          </a>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Judul Laporan</label>
                      <Input
                        type='text'
                        value={judul}
                        name='nama'
                        onChange={(event) => setJudul(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Kategori</label>
                          <Input
                            type='select'
                            name='liga'
                            value={jenis}
                            onChange={(event) => setJenis(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='Pengaduan'>Pengaduan</option>
                            <option value='Aspirasi'>Aspirasi</option>
                            <option value='Permintaan Informasi'>
                              Permintaan Informasi
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Kategori Laporan</label>
                          <Input
                            type='text'
                            value={kategori}
                            name='harga'
                            onChange={(event) =>
                              setKategori(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status Laporan</label>
                          <Input
                            type='select'
                            name='liga'
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='Verifikasi'>Verifikasi</option>
                            <option value='Tindak Lanjut'>Tindak Lanjut</option>
                            <option value='Selesai'>Selesai</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Lokasi</label>
                          <Input
                            type='text'
                            value={lokasi}
                            name='jenis'
                            onChange={(event) => setLokasi(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Sifat Laporan</label>
                          <Input
                            type='select'
                            name='ready'
                            value={sifat}
                            onChange={(event) => setSifat(event.target.value)}
                          >
                            <option value='terbuka'> terbuka </option>
                            <option value='rahasia'> rahasia </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Publikasi</label>
                          <Input
                            type='select'
                            name='ready'
                            value={publikasi}
                            onChange={(event) =>
                              setPublikasi(event.target.value)
                            }
                          >
                            <option value='publik'> publik </option>
                            <option value='anonim'> anonim </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>User Pelapor</label>
                          <Input
                            type='text'
                            name='ready'
                            value={idUser}
                            onChange={(event) => setIdUser(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Instansi</label>
                          <Input
                            type='select'
                            name='ready'
                            value={instansi}
                            onChange={(event) =>
                              setInstansi(event.target.value)
                            }
                          >
                            {listInstansi.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Isi Laporan</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={isi}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setIsi(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
