/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
// react plugin used to create charts
import { Line, Pie } from 'react-chartjs-2';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
// core components
import {
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from 'variables/charts.js';
import axios from 'axios';
import { API_SMART } from 'utils';
import swal from 'sweetalert';

function Dashboard() {
  const [kunHariIni, setKunHariIni] = useState(0);
  const [kunMingguIni, setKunMingguIni] = useState(0);
  const [kunBulanIni, setKunBulanIni] = useState(0);
  const [kunMingguLalu, setKunMingguLalu] = useState(0);
  const [dataRound, setDataRound] = useState();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

  async function loadingData() {
    try {
      const r1 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() `,
      });
      setKunHariIni(r1.data.data);
      const r2 = await axios.get(API_SMART + `kunjunganMingguIni`);
      setKunMingguIni(r2.data.data);
      const r3 = await axios.get(API_SMART + `kunjunganBulanIni`);
      setKunBulanIni(r3.data.data);
      const r4 = await axios.get(API_SMART + `kunjunganMingguLalu`);
      setKunMingguLalu(r4.data.data);
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  }
  async function loadingChartRound() {
    try {
      const r1 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND idUser="" `,
      });

      const r2 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND idUser<>"" `,
      });

      setDataRound([r1.data.data, r2.data.data]);
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  }

  async function loadingChart() {
    try {
      const r1 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Home" `,
      });

      const r2 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Berita" `,
      });

      const r3 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "DaftarHadir" `,
      });

      const r4 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Forum RSS" `,
      });

      const r5 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Pasar UMKM" `,
      });

      const r6 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Pasar Ternak" `,
      });

      const r7 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "BeritaDetail" `,
      });

      const dd = [
        r1.data.data,
        r2.data.data,
        r3.data.data,
        r4.data.data,
        r5.data.data,
        r6.data.data,
        r7.data.data,
      ];

      setData(dd);
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  }
  async function loadingChart2() {
    try {
      const r1 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-1 AND page = "Home" `,
      });

      const r2 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-1 AND page = "Berita" `,
      });

      const r3 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-1 AND page = "DaftarHadir" `,
      });

      const r4 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-1 AND page = "Forum RSS" `,
      });

      const r5 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) =curdate()-1 AND page = "Pasar UMKM" `,
      });

      const r6 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-1 AND page = "Pasar Ternak" `,
      });

      const r7 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-1 AND page = "BeritaDetail" `,
      });

      const dd = [
        r1.data.data,
        r2.data.data,
        r3.data.data,
        r4.data.data,
        r5.data.data,
        r6.data.data,
        r7.data.data,
      ];

      setData2(dd);
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  }

  async function loadingChart3() {
    try {
      const r1 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) =curdate()-2 AND page = "Home" `,
      });

      const r2 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-2 AND page = "Berita" `,
      });

      const r3 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-2 AND page = "DaftarHadir" `,
      });

      const r4 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-2 AND page = "Forum RSS" `,
      });

      const r5 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-2 AND page = "Pasar UMKM" `,
      });

      const r6 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-2 AND page = "Pasar Ternak" `,
      });

      const r7 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `SELECT  dateTime
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate()-2 AND page = "BeritaDetail" `,
      });

      const dd = [
        r1.data.data,
        r2.data.data,
        r3.data.data,
        r4.data.data,
        r5.data.data,
        r6.data.data,
        r7.data.data,
      ];

      setData3(dd);
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  }

  async function loadingChart4() {
    try {
      const r1 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Home"
        GROUP BY idUser`,
      });

      const r2 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Berita"
        GROUP BY idUser`,
      });

      const r3 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "DaftarHadir"
        GROUP BY idUser`,
      });

      const r4 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Forum RSS"
        GROUP BY idUser`,
      });

      const r5 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Pasar UMKM"
        GROUP BY idUser`,
      });

      const r6 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "Pasar Ternak"
        GROUP BY idUser`,
      });

      const r7 = await axios.post(API_SMART + `kunjunganPerPage`, {
        sql: `select idUser, COUNT (*) AS jumlah_orang
        FROM logapp
        WHERE  DATE(CONVERT_TZ(dateTime, '+00:00', '+08:00')) = curdate() AND page = "BeritaDetail"
        GROUP BY idUser`,
      });

      const dd = [
        r1.data.data,
        r2.data.data,
        r3.data.data,
        r4.data.data,
        r5.data.data,
        r6.data.data,
        r7.data.data,
      ];

      setData4(dd);
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  }

  useEffect(() => {
    loadingData();
    loadingChart();
    loadingChart2();
    loadingChart3();
    loadingChart4();
    loadingChartRound();
  }, []);

  const dashboardEmailStatisticsChart = {
    data: (canvas) => {
      return {
        labels: ['non acount', 'acount'],
        datasets: [
          {
            label: 'Akses dengan account vs non account',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: ['#e3e3e3', '#4acccd', '#fcc468', '#ef8157'],
            borderWidth: 0,
            data: dataRound,
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: true,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 2,
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
  const dashboard24HoursPerformanceChart = {
    data: (canvas) => {
      return {
        labels: [
          'Home',
          'Berita',
          'DaftarHadir',
          'Forum RSS',
          'Pasar UMKM',
          'Pasar Ternak',
          'BeritaDetail',
        ],
        datasets: [
          {
            label: 'hari ini',
            borderColor: '#6bd098',
            backgroundColor: '#6bd098',

            borderWidth: 3,
            tension: 0.4,
            pointBorderColor: '#6bd098',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            data: data,
          },
          {
            label: 'Kemarin',
            borderColor: '#f17e5d',
            backgroundColor: '#f17e5d',
            pointBorderColor: '#f17e5d',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            borderWidth: 3,
            tension: 0.4,

            data: data2,
          },
          {
            label: 'Kemarin dulu',
            borderColor: '#4acccd',
            backgroundColor: '#4acccd',
            pointBorderColor: '#4acccd',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            borderWidth: 3,
            tension: 0.4,

            data: data3,
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      scales: {
        y: {
          ticks: {
            color: '#9f9f9f',
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          barPercentage: 1.6,
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 20,
            color: '#9f9f9f',
          },
        },
      },
    },
  };

  const dashboardNASDAQChart = {
    data: (canvas) => {
      return {
        labels: [
          'Home',
          'Berita',
          'DaftarHadir',
          'Forum RSS',
          'Pasar UMKM',
          'Pasar Ternak',
          'BeritaDetail',
        ],
        datasets: [
          {
            data: data,
            label: 'kunjungan',
            fill: false,
            borderColor: '#4acccd',
            backgroundColor: '#4acccd',
            pointBorderColor: '#4acccd',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            tension: 0.4,
          },
          {
            data: data4,
            label: 'user',
            fill: false,
            borderColor: '#e3e3e3',
            backgroundColor: 'transparent',
            pointBorderColor: '#e3e3e3',
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            tension: 0.4,
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: false },
      },
    },
  };

  return (
    <>
      <div className='content'>
        <Row>
          <Col lg='3' md='6' sm='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col md='4' xs='5'>
                    <div className='icon-big text-center icon-warning'>
                      <i className='nc-icon nc-single-02 text-warning' />
                    </div>
                  </Col>
                  <Col md='8' xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Hari ini</p>
                      <CardTitle tag='p'>{kunHariIni}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className='stats'>
                  <i className='fas fa-sync-alt' /> kunjungan
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg='3' md='6' sm='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col md='4' xs='5'>
                    <div className='icon-big text-center icon-warning'>
                      <i className='nc-icon nc-single-02 text-success' />
                    </div>
                  </Col>
                  <Col md='8' xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Minggu ini</p>
                      <CardTitle tag='p'>{kunMingguIni}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className='stats'>
                  <i className='far fa-calendar' /> kunjungan
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg='3' md='6' sm='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col md='4' xs='5'>
                    <div className='icon-big text-center icon-warning'>
                      <i className='nc-icon nc-single-02 text-danger' />
                    </div>
                  </Col>
                  <Col md='8' xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Bulan Ini</p>
                      <CardTitle tag='p'>{kunBulanIni}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className='stats'>
                  <i className='far fa-clock' /> kunjungan
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg='3' md='6' sm='6'>
            <Card className='card-stats'>
              <CardBody>
                <Row>
                  <Col md='4' xs='5'>
                    <div className='icon-big text-center icon-warning'>
                      <i className='nc-icon nc-single-02 text-primary' />
                    </div>
                  </Col>
                  <Col md='8' xs='7'>
                    <div className='numbers'>
                      <p className='card-category'>Minggu Lalu</p>
                      <CardTitle tag='p'>{kunMingguLalu}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className='stats'>
                  <i className='fas fa-sync-alt' /> kunjungan
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader>
                <CardTitle tag='h5'>Kunjungan User</CardTitle>
                <p className='card-category'>Today's performance</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <div className='stats'>
                  <i className='fa fa-history' /> refresh to Updated
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md='4'>
            <Card>
              <CardHeader>
                <CardTitle tag='h5'>Akses Statistics</CardTitle>
                <p className='card-category'>akses dengan account vs non</p>
              </CardHeader>
              <CardBody style={{ height: '266px' }}>
                <Pie
                  data={dashboardEmailStatisticsChart.data}
                  options={dashboardEmailStatisticsChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className='legend'>
                  <i className='fa fa-circle text-primary' /> acount{' '}
                  <i className='fa fa-circle text-gray' /> non acount
                </div>
                <hr />
                <div className='stats'>
                  <i className='fa fa-calendar' /> total akses
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md='8'>
            <Card className='card-chart'>
              <CardHeader>
                <CardTitle tag='h5'>KUNJUNGAN HARI INI</CardTitle>
                <p className='card-category'>Kunjungan vs Users</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboardNASDAQChart.data}
                  options={dashboardNASDAQChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <div className='chart-legend'>
                  <i className='fa fa-circle text-warning' /> kunjungan
                </div>
                <div className='chart-legend'>
                  <i className='fa fa-circle text-warning' /> users
                </div>
                <hr />
                <div className='card-stats'>
                  <i className='fa fa-check' /> Data information certified
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
