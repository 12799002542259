import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { DELETE_EMERGENCY, GET_TOKEN, API_TIMEOUT, GET_INSTANSI } from 'utils';

import swal from 'sweetalert';
import axios from 'axios';
import { logOutUser } from 'actions/AuthAction';
import jwt_decode from 'jwt-decode';
import { dateTime } from 'utils';

import {
  getUserStatusRegister,
  getUserByKategori,
} from 'actions/GetUserAction';

export default function ListSmartUser() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListUserLoading, getListUserResult, getListUserError } =
    useSelector((state) => state.GetUserReducer);

  // auth

  const [token, setToken] = useState('');
  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');
  const [accessToken, setAccessToken] = useState('');
  const [hariIni, sethariIni] = useState('');
  const [labelBulan, setLabelBulan] = useState('');
  const [bulan, setBulan] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [tahun, setTahun] = useState('');
  const [listInstansi, setListInstansi] = useState([]);
  const [instansi, setInstansi] = useState('ALL');

  const [statusUser, setStatusUser] = useState('ASN');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAccessToken(response.data.accessToken);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  //dari smart service

  const getTanggal = () => {
    var date = new Date();
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = '0' + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + '-' + month + '-' + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);

    setLabelBulan(month);

    setTahun(year);

    if (month.length == 1) {
      month = '0' + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = '0' + day;
    }
    var tanggalKu = year + '-' + month + '-' + day;

    setTanggal(tanggalKu);
  };

  useEffect(() => {
    if (instansi != 'ALL') {
      const data = { kategori: 'unitKerja', value: instansi };
      dispatch(getUserByKategori(data));
    }
  }, [instansi]);

  //dari smart service

  useEffect(() => {
    getTanggal();
    getListInstansi();
    refreshToken();
    //dispatch(getUserStatusRegister({ statusRegister: statusUser }));
  }, []);

  // useEffect(() => {
  //   dispatch(getUserStatusRegister({ statusRegister: statusUser }));
  // }, [statusUser]);

  const removeData = async (id) => {
    try {
      await axios.delete(DELETE_EMERGENCY + `${id}`, {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      swal('Failed!', 'Forbiden', 'error');
    } catch (error) {}
  };

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>
                Smart User {statusUser}
                {statusUser == 'ASN  ' ? instansi : ''}{' '}
                {getListUserResult.length} users
              </CardTitle>
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Col md={3}>
                  <FormGroup>
                    <label>Status User</label>
                    <Input
                      type='select'
                      value={statusUser}
                      name='nama'
                      onChange={(event) => setStatusUser(event.target.value)}
                    >
                      <option value={'ASN'}> ASN</option>
                      <option value={'Publik'}> Publik</option>
                    </Input>
                  </FormGroup>
                </Col>
                {statusUser == 'ASN' && (
                  <Col md={6}>
                    <FormGroup>
                      <label>Instansi</label>
                      <Input
                        type='select'
                        value={instansi}
                        name='nama'
                        onChange={(event) => setInstansi(event.target.value)}
                      >
                        <option value={'ALL'}> ALL</option>
                        {listInstansi.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                <Col md={3}>
                  <Link
                    to={'/admin/smartuser/tambah/'}
                    className='btn btn-primary float-right'
                  >
                    Tambah User ASN
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>uid</th>
                    <th>profile</th>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Domisili</th>
                    <th>No Hp</th>
                    <th>Gender</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {getListUserResult ? (
                    Object.keys(getListUserResult).map((key, index) => (
                      <tr key={key}>
                        <td>{index + 1}</td>

                        <td>
                          <img
                            src={getListUserResult[key].avatar}
                            alt={'avatar'}
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 25,
                              borderWidth: 8,
                              borderColor: 'red',
                            }}
                          />
                        </td>

                        <td>{getListUserResult[key].nama}</td>
                        <td>{getListUserResult[key].alamat}</td>
                        <td>{getListUserResult[key].domisili} </td>
                        <td>{getListUserResult[key].nohp} </td>
                        <td>{getListUserResult[key].gender} </td>

                        <td>
                          <Link
                            className='btn btn-warning'
                            to={
                              '/admin/smartuser/edit/' +
                              getListUserResult[key].uid +
                              ',' +
                              idAdmin
                            }
                          >
                            <i className='nc-icon nc-ruler-pencil'></i> Detail
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : getListUserLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' /> Loading....
                      </td>
                    </tr>
                  ) : getListUserError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListUserError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
