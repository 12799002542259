/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { Container, Row } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

function Footer(props) {
  return (
    <footer className={'footer' + (props.default ? ' footer-default' : '')}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className='footer-nav'>
            <ul>
              <li>
                <a href='https://www.creative-tim.com' target='_blank'>
                  Creative Tim
                </a>
              </li>
              <li>
                <a href='https://blog.creative-tim.com' target='_blank'>
                  DKISP
                </a>
              </li>
              <li>
                <a href='https://www.creative-tim.com/license' target='_blank'>
                  Rote Ndao
                </a>
              </li>
            </ul>
          </nav>
          <div className='credits ml-auto'>
            <div className='copyright'>
              &copy; {1900 + new Date().getYear()}, made with{' '}
              <i className='fa fa-heart heart' /> by Informatika
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
