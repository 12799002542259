import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';
import { GET_ALL_ADMINS, API_TIMEOUT } from '../utils/constant';

import axios from 'axios';

export const GET_ADMINS = 'GET_ADMINS';

export const getAdmins = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_ADMINS);

    axios({
      method: 'get',
      url: GET_ALL_ADMINS,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_ADMINS, 'error');
        } else {
          //BERHASIL

          dispatchSuccess(
            dispatch,
            GET_ADMINS,
            response.data.data ? response.data.data : []
          );
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_ADMINS, error);
        alert(error);
      });
  };
};
