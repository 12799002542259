import FIREBASE from '../config/FIREBASE';
import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';

export const GET_USER = 'GET_USER';
export const GET_USER_EMAIL = 'GET_USER_EMAIL';

export const GET_LIST_USER = 'GET_LIST_USER';

export const getUser = (uid) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_USER);

    FIREBASE.database()
      .ref(`users/${uid}`)
      .once('value', (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : [];
        let dataKu = {};
        dataKu.nama = data.nama;
        dataKu.alamat = data.alamat;
        dataKu.nohp = data.nohp ? data.nohp : '';
        dataKu.kecamatan = data.kecamatan ? data.kecamatan : '';
        dataKu.desa = data.desa ? data.desa : '';
        dataKu.fcmToken = data.fcmToken ? data.fcmToken : '';
        if (dataKu) {
          dispatchSuccess(dispatch, GET_USER, dataKu);
        } else {
          dispatchError(dispatch, GET_USER, 'Username tidak ditemukan');
          alert('Username tidak ditemukan');
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_USER, error);
        alert(error);
      });
  };
};

export const getUserDHO = (uid) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_USER);

    FIREBASE.database()
      .ref(`users/${uid}`)
      .once('value', (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : [];

        if (data) {
          dispatchSuccess(dispatch, GET_USER, data);
        } else {
          dispatchError(dispatch, GET_USER, 'Username tidak ditemukan');
          alert('Username tidak ditemukan');
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_USER, error);
        alert(error);
      });
  };
};

export const getUserStatusRegister = (data) => {
  const statusRegister = data.statusRegister;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_USER);

    FIREBASE.database()
      .ref('users')
      .orderByChild('statusRegister')
      .equalTo(statusRegister)
      .once('value', (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        dispatchSuccess(dispatch, GET_LIST_USER, dataKu);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_USER, 'error');
        //alert(error);
      });
  };
};

export const getUserByKategori = (data) => {
  const kategori = data.kategori;
  const value = data.value;
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_USER);

    FIREBASE.database()
      .ref('users')
      .orderByChild(kategori)
      .equalTo(value)
      .once('value', (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        dispatchSuccess(dispatch, GET_LIST_USER, dataKu);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_USER, 'error');
        //alert(error);
      });
  };
};

export const getUserASN = (data) => {
  const kategori = data.kategori;
  const value = data.value;
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_USER);

    FIREBASE.database()
      .ref('users')
      .orderByChild(kategori)
      .equalTo(value)
      .once('value', (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };
        let a = [];
        Object.keys(dataKu).map((key, index) => {
          a.push({ ...dataKu[key] });
        });

        FIREBASE.database()
          .ref('user')
          .orderByChild(kategori)
          .equalTo(value)
          .once('value', (querySnapshot) => {
            let dataKuKu = querySnapshot.val();
            dataKuKu = { ...dataKuKu };

            let b = [];

            Object.keys(dataKuKu).map((key, index) => {
              b.push({ ...dataKuKu[key] });
            });

            // Object.keys(getListUserResult).map((key, index) => (

            let dataFix = b.map((item) => {
              var xx = a.filter((itemKu) => {
                return item.nama == itemKu.nama;
              });

              if (xx.length > 0) {
                return { ...xx[0], jabatan: item.jabatan };
                // return xx[0];
              } else {
                return item;
              }
            });

            dispatchSuccess(dispatch, GET_LIST_USER, dataFix);
          })
          .catch((error) => {
            dispatchError(dispatch, GET_LIST_USER, 'error');
            //alert(error);
          });
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_USER, 'error');
        //alert(error);
      });
  };
};

export const getUserEmail = (email) => {
  console.log(email);
  return (dispatch) => {
    dispatchLoading(dispatch, GET_USER_EMAIL);

    FIREBASE.database()
      .ref('users')
      .orderByChild('email')
      .equalTo(email)
      .once('value', (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : [];

        let arrayOfNames = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const nama = data[key];
            arrayOfNames.push(nama);
          }
        }

        console.log(arrayOfNames[0]);

        if (data) {
          dispatchSuccess(dispatch, GET_USER_EMAIL, arrayOfNames[0]);
        } else {
          dispatchError(dispatch, GET_USER_EMAIL, 'Username tidak ditemukan');
          alert('Username tidak ditemukan');
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_USER_EMAIL, error);
        alert(error);
      });
  };
};
