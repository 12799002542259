import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { getAngkutSampah } from '../../actions/AngkutSampahAction';
import { substractDates } from 'utils';
import { numberWithCommas } from 'utils';

export default function ListAngkutSampah() {
  const dispatch = useDispatch();

  const {
    getListAngkutSampahError,
    getListAngkutSampahLoading,
    getListAngkutSampahResult,
  } = useSelector((state) => state.AngkutSampahReducer);

  useEffect(() => {
    dispatch(getAngkutSampah());
  }, []);

  // componentDidUpdate(prevProps) {
  //   const { deleteJerseyResult } = this.props;

  //   if (
  //     deleteJerseyResult &&
  //     prevProps.deleteJerseyResult !== deleteJerseyResult
  //   ) {
  //     swal('Sukses!', deleteJerseyResult, 'success');
  //     this.props.dispatch(getListJersey());
  //   }
  // }

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>List Angkut Sampah</CardTitle>
            </CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Tanggal</th>
                    <th>Alamat</th>
                    <th>Pemesan</th>
                    <th>No Hp</th>
                    <th>Status</th>
                    <th>Ukuran</th>
                    <th>Jumlah</th>
                    <th>Harga</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {getListAngkutSampahResult ? (
                    Object.keys(getListAngkutSampahResult).map((key) => {
                      let waktu = substractDates(
                        getListAngkutSampahResult[key].tanggal
                      );

                      return (
                        <tr key={key}>
                          <td
                            style={{
                              backgroundColor:
                                waktu.keterangan == 'hari ini' ? 'red' : '',
                            }}
                          >
                            <text
                              style={{
                                color:
                                  waktu.keterangan == 'hari ini' ? 'white' : '',
                                fontWeight: 500,
                              }}
                            >
                              {waktu.keterangan}
                            </text>
                          </td>
                          <td>{getListAngkutSampahResult[key].alamat}</td>
                          <td>
                            {getListAngkutSampahResult[key].nama.split('##')[0]}
                          </td>
                          <td>
                            {getListAngkutSampahResult[key].nama.split('##')[1]}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                getListAngkutSampahResult[key].status == 'T'
                                  ? 'green'
                                  : '',
                            }}
                          >
                            <text
                              style={{
                                color:
                                  getListAngkutSampahResult[key].status == 'T'
                                    ? 'white'
                                    : '',
                              }}
                            >
                              {getListAngkutSampahResult[key].status == 'D'
                                ? 'Dalam Proses'
                                : getListAngkutSampahResult[key].status == 'B'
                                ? 'Batal'
                                : 'Selesai'}
                            </text>
                          </td>
                          <td>
                            {
                              getListAngkutSampahResult[key].ukuran.split(
                                ','
                              )[0]
                            }{' '}
                          </td>
                          <td>{getListAngkutSampahResult[key].jumlah} </td>
                          <td>
                            Rp.
                            {numberWithCommas(
                              getListAngkutSampahResult[key].harga
                            )}{' '}
                          </td>
                          <td>
                            <Link
                              className='btn btn-warning'
                              to={
                                '/admin/angkutSampah/edit/' +
                                getListAngkutSampahResult[key].id
                              }
                            >
                              <i className='nc-icon nc-ruler-pencil'></i> Edit
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  ) : getListAngkutSampahLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : getListAngkutSampahError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListAngkutSampahError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
