import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfile } from 'actions/AuthAction';

import axios from 'axios';
import { GET_TOKEN, GET_INSTANSI } from 'utils';
import { getUserEmail, getUserDHO } from 'actions/GetUserAction';

export default function EditAdminLayanan() {
  const { id } = useParams();
  const idKu = id.split(',');

  const dispatch = useDispatch();

  const { getUserEmailError, getUserEmailResult, getUserEmailLoading } =
    useSelector((state) => state.GetUserReducer);

  const { getUserError, getUserResult, getUserLoading } = useSelector(
    (state) => state.GetUserReducer
  );

  const { updateLoading, updateResult } = useSelector(
    (state) => state.AuthReducer
  );

  const history = useHistory();

  const [instansi, setInstansi] = useState('');
  const [nama, setNama] = useState('');
  const [waktuMasuk, setWaktuMasuk] = useState('');

  const [status, setStatus] = useState('');
  const [avatar, setAvatar] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [listInstansi, setListInstansi] = useState([]);

  const [email, setEmail] = useState('');
  const [uid, setUid] = useState('');
  const [gambar, setGambar] = useState('');

  const [statusRegister, setStatusRegister] = useState('');

  const [userId, setUserId] = useState('');
  const [edit, setEdit] = useState(false);
  const [aplikasi, setAplikasi] = useState('');
  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        // history.push('/admin/smartuser');
      }
    }
  };
  //auth

  useEffect(() => {
    if (id) {
      dispatch(getUserDHO(idKu[0]));
    }
  }, [id]);

  const simpanAdmin = async () => {
    setSaveLoading(true);
    const formData = new FormData();
    formData.append('photo', gambar);

    formData.append('uid', uid);
    formData.append('dinas', instansi);
    formData.append('username', email);
    formData.append('status', status);

    formData.append('nama', nama);

    const response = await axios.post(
      'https://tulufali.rotendaokab.go.id/api/postadmin',
      formData,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data.success === 1) {
      setGambar('');
      setUserId('');
      setUid('');
      setInstansi('');
      setEmail('');
      setNama('');
      swal('Success', 'Admin ' + nama + ' berhasil diinput', 'success');
      setSaveLoading(false);
      setAvatar('');
      setStatusRegister('');
    }
  };

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refreshToken();
    getListInstansi();
    var today = new Date();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    setWaktuMasuk(time);
  }, []);

  const getUserSmart = () => {
    dispatch(getUserEmail(userId));
  };

  const convertGambar = (avatar) => {
    // Data gambar dalam format data URI
    const imageDataURI = avatar; // Sisipkan data gambar lengkap di sini

    // Memisahkan data URI menjadi tipe dan base64 encoded data
    const [dataType, base64Data] = imageDataURI.split(',');

    // Mengubah base64 encoded data menjadi bentuk binary
    const binaryData = atob(base64Data);

    // Mendapatkan panjang data binary
    const len = binaryData.length;

    // Membuat array untuk menyimpan data binary dalam bentuk Uint8Array
    const uint8Array = new Uint8Array(len);

    // Mengisi array dengan data binary
    for (let i = 0; i < len; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Membuat file baru dari Uint8Array dan menambahkan tipe datanya
    const file = new File([uint8Array], 'gambar.jpg', { type: dataType });

    setGambar(file);
  };

  useEffect(() => {
    if (id) {
      dispatch(getUserDHO(idKu[0]));
    }
  }, [id]);

  useEffect(() => {
    if (getUserResult) {
      setNama(getUserResult.nama);

      setAvatar(getUserResult.avatar);

      if (getUserResult.avatar) {
        convertGambar(getUserResult.avatar);
      }
      setEmail(getUserResult.email);

      setUid(getUserResult.uid);

      setStatus(getUserResult.status);
      setStatusRegister(getUserResult.statusRegister);

      if (getUserResult.statusRegister == 'ASN') {
        setInstansi(getUserResult.unitKerja);
      }
    }
  }, [getUserResult]);

  useEffect(() => {
    if (getUserEmailResult) {
      setNama(getUserEmailResult.nama);

      setAvatar(getUserEmailResult.avatar);

      if (getUserEmailResult.avatar) {
        convertGambar(getUserEmailResult.avatar);
      }
      setEmail(getUserEmailResult.email);

      setUid(getUserEmailResult.uid);

      setStatus(getUserEmailResult.status);
      setStatusRegister(getUserEmailResult.statusRegister);

      if (getUserEmailResult.statusRegister == 'ASN') {
        setInstansi(getUserEmailResult.unitKerja);
      }
    }
  }, [getUserEmailResult]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: 'Apakah anda yakin ?',
      text:
        'Akan menyimpan data ' +
        nama +
        ' sebagai admin aplikasi ' +
        aplikasi +
        ' !',
      icon: 'warning',
      buttons: ['Tidak, batalkan!', 'Ya, saya yakin!'],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        simpanAdmin();
      } else {
        swal('Cancelled', 'Data Admin tidak jadi disimpan  :)', 'error');
      }
    });
  };

  // const updateProfil = () => {
  //   setSaveLoading(true);
  //   // this.props.dispatch(updateJersey(this.state));
  //   const data = {
  //     uid: uid,
  //     nama: nama,
  //     alamat: alamat,
  //     nohp: nohp,
  //     kecamatan: kecamatan,
  //     desa: desa,
  //     email: email,
  //     pangkat: pangkat,
  //     unitKerja: instansi,
  //     nip: nip,
  //     status: status,
  //     avatar: avatar,
  //     domisili: domisili,
  //     statusRegister: statusRegister,
  //     provinsi: provinsi,
  //     kota: kota,
  //     dob: new Date(dob),
  //     date: new Date(date),
  //     gender: gender,
  //     fcmToken: fcmToken,
  //     token: token,
  //     nik: nik,
  //     namaPengguna: namaPengguna,
  //   };

  //   dispatch(updateProfile(data));

  //   setSaveLoading(false);
  // };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/adminlayanan' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Data Admin Aplikasi</CardHeader>
            <CardBody>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label>Email Rote Smart Service</label>
                    <Input
                      onChange={(e) => setUserId(e.target.value)}
                      type='text'
                      value={userId}
                      name='nama'
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  {getUserEmailLoading ? (
                    <Button color='primary' type='submit' disabled>
                      <Spinner size='sm' color='light' /> Loading
                    </Button>
                  ) : (
                    <Button
                      onClick={() => getUserSmart()}
                      type='submit'
                      color='danger'
                      className='ml-2'
                    >
                      <i className='nc-icon nc-zoom-split'></i> Cari
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <CardBody>
                  {nama && (
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          Status
                          <Input
                            type='select'
                            name='jenis'
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                            required
                          >
                            <option value={''}> - Pilih status admin -</option>
                            <option value={'admin'}>admin</option>
                            <option value={'super admin'}>super admin</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          Admin untuk aplikasi
                          <Input
                            type='select'
                            name='jenis'
                            value={aplikasi}
                            onChange={(event) =>
                              setAplikasi(event.target.value)
                            }
                            required
                          >
                            <option value={''}> - Pilih aplikasi -</option>
                            <option value={'Tulu Fali'}>Tulu Fali</option>
                            <option value={'Kalkulator Gizi'}>
                              Kalkulator Gizi
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <label>Nama</label>
                        <Input type='text' value={nama} name='nama' disabled />
                      </FormGroup>

                      <FormGroup>
                        <label>Perangkat Daerah</label>
                        <Input
                          type='select'
                          value={instansi}
                          name='jenis'
                          onChange={(event) => setInstansi(event.target.value)}
                        >
                          <option value={''}>- Pilih Dinas -</option>
                          {listInstansi.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <label>Status Registrasi</label>
                            <Input
                              type='text'
                              value={statusRegister}
                              name='jenis'
                              disabled
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>email</label>
                            <Input
                              type='text'
                              name='harga'
                              value={email}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <img
                            src={avatar}
                            alt={'avatar'}
                            style={{
                              width: 200,
                              height: 200,
                              borderRadius: 100,
                              borderWidth: 8,
                              borderColor: 'red',
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    {!edit ? (
                      <Col>
                        {saveLoading ? (
                          <Button color='primary' type='submit' disabled>
                            <Spinner size='sm' color='light' /> Loading
                          </Button>
                        ) : (
                          <Button type='submit' color='danger' className='ml-2'>
                            <i className='nc-icon nc-basket'></i> Simpan
                          </Button>
                        )}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </CardBody>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
