export const API_SMART = 'https://smartservice.rotendaokab.go.id/api/admin/';
//'http://localhost:4000/api/admin/';

//'https://smartservice.rotendaokab.go.id/api/admin/';
//export const API_SMART = 'http://192.168.43.39:4000/api/';

export const API_KEY = '49d84d5ea3e55256171e1c98251c1b6b';

export const API_DAFTARHADIR = API_SMART + 'daftarHadir';

export const API_DAFTARHADIR_CEK = API_SMART + 'daftarHadir/cek';

export const POST_LAPORAN = API_SMART + 'laporan';
export const POST_LAPORAN_KOMENTAR = API_SMART + 'laporan/komentar';
export const POST_LAPORAN_LIKE = API_SMART + 'laporan/like';

export const DELETE_LAPORAN_KOMENTAR = API_SMART + 'laporan/laporkomen/';
export const DELETE_LAPORAN_LIKE = API_SMART + 'laporan/like/';

export const EDIT_LAPORAN = API_SMART + 'laporan/';
export const GET_LOGAPI = API_SMART + 'logapi/';

export const POST_DAFTARHADIR = API_SMART + 'daftarHadir';
export const UPDATE_DAFTARHADIR = API_SMART + 'daftarHadir/';
export const CEK_DAFTARHADIR = API_SMART + 'daftarHadir/cek';

export const GET_DAFTARHADIR = API_SMART + 'daftarHadir/getdh';

export const GET_DAFTARHADIR_ATURAN = API_SMART + 'daftarHadir/aturan/';
export const GET_DAFTARHADIR_LIBUR = API_SMART + 'daftarHadir/libur/';
export const GET_DAFTARHADIR_KOORDINATOPD =
  API_SMART + 'daftarHadir/koordinatopd/';

export const GET_BERITA = API_SMART + 'berita/';

export const GET_BERITAPORTAL = API_SMART + 'beritaPortal/';

export const POST_BERITA = API_SMART + 'berita';
export const EDIT_BERITA = API_SMART + 'berita/';
export const DELETE_BERITA = API_SMART + 'berita/';
export const GET_BERITA_DETAIL = API_SMART + 'berita/';
export const UPDATE_BERITA = API_SMART + 'berita/';

export const GET_NOTIFIKASI = API_SMART + 'notifikasi/';
export const POST_NOTIFIKASI = API_SMART + 'notifikasi';
export const EDIT_NOTIFIKASI = API_SMART + 'notifikasi/';
export const DELETE_NOTIFIKASI = API_SMART + 'notifikasi/';
export const GET_NOTIFIKASI_DETAIL = API_SMART + 'notifikasi/';
export const UPDATE_NOTIFIKASI = API_SMART + 'notifikasi/';

export const POST_NOTIFIKASI_PRIBADI = API_SMART + 'notifikasipribadi';

export const GET_TOKO = API_SMART + 'toko/';
export const POST_TOKO = API_SMART + 'toko';
export const EDIT_TOKO = API_SMART + 'toko/';
export const DELETE_TOKO = API_SMART + 'toko/';
export const GET_TOKO_DETAIL = API_SMART + 'toko/';
export const UPDATE_TOKO = API_SMART + 'toko/';

export const GET_BANNER = API_SMART + 'banner/';
export const POST_BANNER = API_SMART + 'banner';
export const EDIT_BANNER = API_SMART + 'banner/';
export const DELETE_BANNER = API_SMART + 'banner/';
export const GET_BANNER_DETAIL = API_SMART + 'banner/';

export const GET_EMERGENCY = API_SMART + 'emergency/';
export const POST_EMERGENCY = API_SMART + 'emergency';
export const EDIT_EMERGENCY = API_SMART + 'emergency/';
export const DELETE_EMERGENCY = API_SMART + 'emergency/';
export const GET_EMERGENCY_DETAIL = API_SMART + 'emergency/';

export const GET_SERVICE = API_SMART + 'service/';
export const POST_SERVICE = API_SMART + 'service';
export const EDIT_SERVICE = API_SMART + 'service/';
export const DELETE_SERVICE = API_SMART + 'service/';
export const GET_SERVICE_DETAIL = API_SMART + 'service/';
export const UPDATE_SERVICE = API_SMART + 'service/';

export const POST_DESTINASI = API_SMART + 'destinasi';
export const GET_DESTINASI = API_SMART + 'destinasi';
export const EDIT_DESTINASI = API_SMART + 'destinasi/';
export const GET_DESTINASI_DETAIL = API_SMART + 'destinasi/';
export const DELETE_DESTINASI = API_SMART + 'destinasi/';

export const UPDATE_ANGKUTSAMPAH = API_SMART + 'angkutsampah/';
export const GET_ANGKUTSAMPAH_ALL = API_SMART + 'angkutsampah/all/';
export const GET_ANGKUTSAMPAH_SELESAI = API_SMART + 'angkutsampah/selesai/';
export const GET_ANGKUTSAMPAH_DETAIL = API_SMART + 'angkutsampah/';
export const DELETE_ANGKUTSAMPAH = API_SMART + 'angkutsampah/';

export const GET_PROGRES_ANGKUTSAMPAH = API_SMART + 'progresangkutsampah/';

export const POST_PROGRES_ANGKUTSAMPAH = API_SMART + 'progresangkutsampah/';

export const GET_DESA = API_SMART + 'desa/';

export const GET_INSTANSI = API_SMART + 'instansi/';

export const GET_LAPORAN = API_SMART + 'laporan/';

export const DELETE_LAPORAN = API_SMART + 'laporan/';

export const GET_LAPORAN_DETAIL = API_SMART + 'laporan/';

export const GET_LAPORAN_KOMEN = API_SMART + 'laporan/komentar';

export const GET_LAPORAN_LIKE = API_SMART + 'laporan/like';

export const GET_PRODUK = API_SMART + 'produk/';

export const GET_PRODUK_DEKRANASDA = API_SMART + 'produk/dekranasda';

export const POST_PRODUK = API_SMART + 'produk';
export const GET_PRODUK_DETAIL = API_SMART + 'produk/';
export const EDIT_PRODUK = API_SMART + 'produk/';
export const DELETE_PRODUK = API_SMART + 'produk/';

export const GET_PESANAN_ALL = API_SMART + 'pesananprosesall/';
export const GET_PESANAN_DETAIL = API_SMART + 'pesanan/';
export const EDIT_PESANAN = API_SMART + 'pesanan/';

export const GET_KECAMATAN = API_SMART + 'kecamatan/';

export const GET_VIDEO = API_SMART + 'video/';

export const GET_VIDEO_PORTAL = API_SMART + 'video/portal';

export const POST_VIDEO = API_SMART + 'video';

export const EDIT_VIDEO = API_SMART + 'video/';
export const GET_VIDEO_DETAIL = API_SMART + 'video/';
export const DELETE_VIDEO = API_SMART + 'video/';

//pasar ternak
export const GET_ALL_PASARTERNAK = API_SMART + 'pasarternakall/';
export const EDIT_PASARTERNAK = API_SMART + 'pasarternak/';
export const GET_PASARTERNAK_DETAIL = API_SMART + 'pasarternak/';
export const DELETE_PASARTERNAK = API_SMART + 'pasarternak/';

//pasar ternak

///adminnnnnn
export const GET_ALL_ADMINS = API_SMART + 'admins/';

export const ADMINS_LOGIN = API_SMART + 'admins/login';
export const ADMINS_LOGOUT = API_SMART + 'admins/';

export const POST_ADMIN = API_SMART + 'admins';

export const EDIT_ADMIN_BY_ID = API_SMART + 'admins/';
export const GET_ADMIN_BY_ID = API_SMART + 'admins/';
export const DELETE_ADMIN = API_SMART + 'admins/';
export const GET_TOKEN = API_SMART + 'admins/token';

//admin

export const POST_FASILITAS_WISATA = API_SMART + 'fasilitaswisata';

export const GET_FASILITASWISATA = API_SMART + 'fasilitaswisata';

export const GET_FASILITASWISATA_DESTINASI =
  API_SMART + 'fasilitaswisata/destinasi/';

export const GET_FASILITASWISATA_DESTINASI_DETAIL =
  API_SMART + 'fasilitaswisata/';

export const EDIT_FASILITASWISATA = API_SMART + 'fasilitaswisata/';

export const DELETE_FASILITASWISATA = API_SMART + 'fasilitaswisata/';

export const API_TIMEOUT = 120000;

export const ORIGIN_CITY = '383';
