import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
  FormGroup,
  Input,
  CardFooter,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { getEmergency } from '../../actions/EmergencyAction';
import { DELETE_EMERGENCY, GET_TOKEN, API_TIMEOUT, GET_INSTANSI } from 'utils';

import swal from 'sweetalert';
import axios from 'axios';
import { logOutUser } from 'actions/AuthAction';
import jwt_decode from 'jwt-decode';
import { dateTime } from 'utils';
import {
  getListDaftarHadir,
  getListDaftarHadirInstansi,
} from 'actions/DaftarHadirAction';

export default function ListDaftarHadir() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getListDHLoading, getListDHResult, getListDHError } = useSelector(
    (state) => state.DaftarHadirReducer
  );

  // auth

  const [token, setToken] = useState('');
  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');
  const [accessToken, setAccessToken] = useState('');
  const [hariIni, sethariIni] = useState('');
  const [labelBulan, setLabelBulan] = useState('');
  const [bulan, setBulan] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [tahun, setTahun] = useState('');
  const [listInstansi, setListInstansi] = useState([]);
  const [instansi, setInstansi] = useState('ALL');

  const [hadir, sethadir] = useState(0);
  const [dinasLuar, setDinasLuar] = useState(0);
  const [izin, setIzin] = useState(0);
  const [tugasBelajar, setTugasBelajar] = useState(0);
  const [sakit, setSakit] = useState(0);
  const [tanpaBerita, setTanpaBerita] = useState(0);
  const [kosong, setKosong] = useState(0);

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAccessToken(response.data.accessToken);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  //dari smart service

  const getTanggal = () => {
    var date = new Date();
    var month = date.getMonth() + 1;
    month = month.toString();

    if (month.length == 1) {
      month = '0' + month;
    }
    var day = new Date().getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    var year = new Date().getFullYear().toString();
    var tanggalKu = year + '-' + month + '-' + day;

    let hariIniKu = dateTime(new Date());

    sethariIni(hariIniKu);
    setLabelBulan(month);
    setTahun(year);

    if (month.length == 1) {
      month = '0' + month;
    }

    setBulan(month);

    if (day.length === 1) {
      day = '0' + day;
    }
    var tanggalKu = year + '-' + month + '-' + day;

    setTanggal(tanggalKu);
  };

  useEffect(() => {
    if (getListDHResult) {
      let hadir = 0;
      let dinasLuar = 0;
      let izin = 0;
      let tugasBelajar = 0;
      let sakit = 0;
      let tanpaBerita = 0;
      let kosong = 0;

      getListDHResult.map((item) => {
        switch (item.status) {
          case 'Hadir':
            hadir = hadir + 1;
            break;
          case 'Dinas Luar':
            dinasLuar = dinasLuar + 1;
            break;
          case 'Izin':
            izin = izin + 1;
            break;
          case 'Tugas Belajar':
            tugasBelajar = tugasBelajar + 1;
            break;
          case 'Sakit':
            sakit = sakit + 1;
            break;
          case 'Tanpa Berita':
            tanpaBerita = sakit + 1;
            break;
          case '':
            kosong = kosong + 1;
            break;
        }
      });
      sethadir(hadir);
      setDinasLuar(dinasLuar);
      setIzin(izin);
      setSakit(sakit);
      setTugasBelajar(tugasBelajar);
      setKosong(kosong);
    }
  }, [getListDHResult]);

  useEffect(() => {
    if (tanggal && instansi != 'ALL') {
      const data = { tanggal: tanggal, unitKerja: instansi };
      dispatch(getListDaftarHadirInstansi(data));
    } else if (tanggal && instansi == 'ALL') {
      const data = { tanggal: tanggal, unitKerja: 'unitKerja' };
      dispatch(getListDaftarHadir(data));
    }
  }, [instansi, tanggal]);

  //dari smart service

  useEffect(() => {
    getTanggal();
    getListInstansi();
    refreshToken();
  }, []);

  const removeData = async (id) => {
    try {
      await axios.delete(DELETE_EMERGENCY + `${id}`, {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      swal('Failed!', 'Forbiden', 'error');
    } catch (error) {}
  };

  return (
    <div className='content'>
      <Row>
        <Col lg='3' md='6' sm='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <Col md='4' xs='5'>
                  <div className='icon-big text-center icon-warning'>
                    <i className='nc-icon nc-globe text-warning' />
                  </div>
                </Col>
                <Col md='8' xs='7'>
                  <div className='numbers'>
                    <p className='card-category'>Hadir</p>
                    <CardTitle tag='p'>{hadir} ASN</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='fas fa-sync-alt' />{' '}
                {((hadir / getListDHResult.length) * 100).toFixed(2)} %
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg='3' md='6' sm='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <Col md='4' xs='5'>
                  <div className='icon-big text-center icon-warning'>
                    <i className='nc-icon nc-money-coins text-success' />
                  </div>
                </Col>
                <Col md='8' xs='7'>
                  <div className='numbers'>
                    <p className='card-category'>Dinas Luar</p>
                    <CardTitle tag='p'>{dinasLuar} ASN</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='far fa-calendar' />{' '}
                {((dinasLuar / getListDHResult.length) * 100).toFixed(2)} %
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg='3' md='6' sm='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <Col md='4' xs='5'>
                  <div className='icon-big text-center icon-warning'>
                    <i className='nc-icon nc-vector text-danger' />
                  </div>
                </Col>
                <Col md='8' xs='7'>
                  <div className='numbers'>
                    <p className='card-category'>Lainya</p>
                    <CardTitle tag='p'>
                      {izin + sakit + tugasBelajar + kosong} ASN
                    </CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='far fa-clock' />{' '}
                {(
                  ((izin + sakit + tugasBelajar + kosong) /
                    getListDHResult.length) *
                  100
                ).toFixed(2)}{' '}
                %
              </div>
            </CardFooter>
          </Card>
        </Col>

        <Col lg='3' md='6' sm='6'>
          <Card className='card-stats'>
            <CardBody>
              <Row>
                <Col md='4' xs='5'>
                  <div className='icon-big text-center icon-warning'>
                    <i className='nc-icon nc-favourite-28 text-primary' />
                  </div>
                </Col>
                <Col md='8' xs='7'>
                  <div className='numbers'>
                    <p className='card-category'>Total ASN</p>
                    <CardTitle tag='p'>{getListDHResult.length} ASN</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className='stats'>
                <i className='fas fa-sync-alt' />{' '}
                {instansi == 'ALL' ? 'ASN yang mengisi DH' : 'ASN pada PD'}
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>
                Daftar Hadir {hariIni && hariIni.tanggalLengkap}
              </CardTitle>
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Col md={6}>
                  <FormGroup>
                    <label>Instansi</label>
                    <Input
                      type='select'
                      value={instansi}
                      name='nama'
                      onChange={(event) => setInstansi(event.target.value)}
                    >
                      <option value={'ALL'}> ALL</option>
                      {listInstansi.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Link
                    to={'/admin/daftarhadir/tambah/'}
                    className='btn btn-primary float-right'
                  >
                    Isi Daftar Hadir
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Id</th>
                    <th>Instansi</th>
                    <th>Nama</th>
                    <th>Status</th>
                    <th>Waktu Masuk</th>
                    <th>Waktu Pulang</th>
                    <th>Working Hours</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {getListDHResult ? (
                    Object.keys(getListDHResult).map((key, index) => (
                      <tr key={key}>
                        <td>{index + 1}</td>
                        {instansi == 'ALL' ? (
                          <td>{getListDHResult[key].opd}</td>
                        ) : (
                          <td>
                            <img
                              src={getListDHResult[key].avatar}
                              alt={'avatar'}
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: 25,
                                borderWidth: 8,
                                borderColor: 'red',
                              }}
                            />
                          </td>
                        )}

                        <td>{getListDHResult[key].nama}</td>
                        <td>{getListDHResult[key].status}</td>
                        <td>{getListDHResult[key].waktuMasuk} </td>
                        <td>{getListDHResult[key].waktuPulang} </td>
                        <td>{getListDHResult[key].workingHours} </td>
                        {instansi != 'ALL' ? (
                          <td>
                            <Link
                              className='btn btn-warning'
                              to={
                                '/admin/daftarhadir/edit/' +
                                getListDHResult[key].uid +
                                ',' +
                                idAdmin +
                                ',' +
                                tanggal
                              }
                            >
                              <i className='nc-icon nc-ruler-pencil'></i> Catat
                            </Link>
                          </td>
                        ) : (
                          <td>
                            <Button
                              color='danger'
                              className='ml-2'
                              onClick={() =>
                                removeData(getListDHResult[key].id)
                              }
                            >
                              <i className='nc-icon nc-basket'></i> Hapus
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : getListDHLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : getListDHError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListDHError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
