import { GET_LIST_PESANANALL } from '../../actions/PesananMasukAction';

const initialState = {
  getListPesananAllLoading: false,
  getListPesananAllResult: false,
  getListPesananAllError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PESANANALL:
      return {
        ...state,
        getListPesananAllLoading: action.payload.loading,
        getListPesananAllResult: action.payload.data,
        getListPesananAllError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
