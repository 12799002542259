import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';
import { GET_EMERGENCY, API_TIMEOUT } from '../utils/constant';

import axios from 'axios';

export const GET_LIST_EMERGENCY = 'GET_LIST_EMERGENCY';

export const getEmergency = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_EMERGENCY);

    axios({
      method: 'get',
      url: GET_EMERGENCY,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_EMERGENCY, 'error');
        } else {
          //BERHASIL

          dispatchSuccess(
            dispatch,
            GET_LIST_EMERGENCY,
            response.data.data ? response.data.data : []
          );
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_EMERGENCY, 'error');
        alert(error);
      });
  };
};
