import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import { GET_PRODUK_DETAIL } from 'utils';
import axios from 'axios';
import { EDIT_PRODUK, GET_TOKEN } from 'utils';

export default function EditProduk() {
  const { id } = useParams();
  const idKu = id.split(',');
  const history = useHistory();
  const ukurans = ['S', 'M', 'L', 'XL', 'XXL', 'besar', 'kecil', 'sedang'];

  const [gambar, setGambar] = useState('');
  const [gambar2, setGambar2] = useState('');
  const [nama, setNama] = useState('');
  const [jenis, setJenis] = useState('');
  const [berat, setBerat] = useState('');
  const [bintang, setBintang] = useState('');
  const [harga, setHarga] = useState('');
  const [ready, setReady] = useState('');
  const [toko, setToko] = useState('');
  const [ukuran, setUkuran] = useState('');
  const [checked, setChecked] = useState([]);
  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [urlFile2, setUrlFile2] = useState(DefaultImage);

  const [gambarLama1, setGambarLama1] = useState(false);
  const [gambarLama2, setGambarLama2] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/produk');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
    getDetailProduk();
  }, [id]);

  const getDetailProduk = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_PRODUK_DETAIL + `${idKu[0]}`);
      data1 = response.data.data[0];

      if (data1) {
        setUrlFile1(data1.gambar.split(',')[0]);
        setUrlFile2(data1.gambar.split(',')[1]);

        setGambarLama1(data1.gambar.split(',')[0]);
        setGambarLama2(data1.gambar.split(',')[1]);

        setNama(data1.nama);
        setJenis(data1.jenis);
        setBerat(data1.berat);
        setBintang(data1.bintang);
        setHarga(data1.harga);
        setReady(data1.ready);
        setToko(data1.toko);
        setUkuran(data1.ukuran);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
  }

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
    setGambar2(e.target.files[0]);
  }

  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (nama && jenis && bintang && harga && ready && toko) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      //mengecek apakah ada perubahan foto

      let rubah1 = gambarLama1.trim() !== urlFile1.trim() ? 'True' : 'False';
      let rubah2 = gambarLama2.trim() !== urlFile2.trim() ? 'True' : 'False';

      const formData = new FormData();

      formData.append('rubahFoto1', rubah1);
      formData.append('rubahFoto2', rubah2);

      formData.append('gambarLama1', gambarLama1);
      formData.append('gambarLama2', gambarLama2);

      formData.append('photo', gambar);
      formData.append('photo', gambar2);

      formData.append('nama', nama);
      formData.append('jenis', jenis);
      formData.append('berat', berat);
      formData.append('bintang', bintang);
      formData.append('harga', harga);
      formData.append('ready', ready);
      formData.append('toko', toko);
      formData.append('ukuran', checked);
      formData.append('idAdmin', idKu[1]);

      const response = await axios.patch(EDIT_PRODUK + `${idKu[0]}`, formData, {
        credentials: 'include',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Produk berhasil diedit', 'success');
        history.push('/admin/produk');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/produk' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Produk</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Produk (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={urlFile2}
                          width='300'
                          alt='Foto Produk (dua)'
                        />
                        <FormGroup>
                          <label>Foto Produk (dua)</label>
                          <Input
                            type='file'
                            name='image2'
                            accept={'image/*'}
                            onChange={(event) => onChange2(event, 'imageToDB2')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama Produk</label>
                      <Input
                        type='text'
                        value={nama}
                        name='nama'
                        onChange={(event) => setNama(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Jenis</label>
                          <Input
                            type='select'
                            name='liga'
                            value={jenis}
                            onChange={(event) => setJenis(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='Replika Top Quality'>
                              Replika Top Quality{' '}
                            </option>
                            <option value='Kerajinan Tangan'>
                              Kerajinan Tangan
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Berat</label>
                          <Input
                            type='number'
                            value={berat}
                            name='harga'
                            onChange={(event) => setBerat(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Bintang</label>
                          <Input
                            type='number'
                            value={bintang}
                            name='berat'
                            onChange={(event) => setBintang(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Harga</label>
                          <Input
                            type='number'
                            value={harga}
                            name='jenis'
                            onChange={(event) => setHarga(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Toko</label>
                          <Input
                            type='text'
                            value={toko}
                            name='jenis'
                            onChange={(event) => setToko(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Ready</label>
                          <Input
                            type='select'
                            name='ready'
                            value={ready}
                            onChange={(event) => setReady(event.target.value)}
                          >
                            <option value={'ada'}>Ada</option>
                            <option value={'Kosong'}>Kosong</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <label>Ukuran yang Tersedia</label>
                        <FormGroup check>
                          {ukurans.map((ukuran, index) => (
                            <Label key={index} check className='mr-2'>
                              <Input
                                type='checkbox'
                                value={ukuran}
                                onChange={(event) => changeCheck(event)}
                              />
                              {ukuran}
                              <span className='form-check-sign'>
                                <span className='check'></span>
                              </span>
                            </Label>
                          ))}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
