import { GET_ADMINS } from '../../actions/AdminAction';

const initialState = {
  getListAdminLoading: false,
  getListAdminResult: false,
  getListAdminError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMINS:
      return {
        ...state,
        getListAdminLoading: action.payload.loading,
        getListAdminResult: action.payload.data,
        getListAdminError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
