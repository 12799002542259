import { GET_LIST_PASARTERNAK } from '../../actions/PasarTernakAction';

const initialState = {
  getListPasarTernakLoading: false,
  getListPasarTernakResult: false,
  getListPasarTernakError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PASARTERNAK:
      return {
        ...state,
        getListPasarTernakLoading: action.payload.loading,
        getListPasarTernakResult: action.payload.data,
        getListPasarTernakError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
