import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import axios from 'axios';
import { POST_NOTIFIKASI } from 'utils';

export default function TambahNotifikasi() {
  const { id } = useParams();
  const history = useHistory();

  const [dateTime, setDateTime] = useState(new Date());
  const [judul, setJudul] = useState('');
  const [isi, setIsi] = useState('');
  const [foto, setFoto] = useState('');
  const [sumber, setSumber] = useState('');
  const [idAdmin, setIdAdmin] = useState('FfFf');
  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [saveLoading, setSaveLoading] = useState(false);

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFoto(e.target.files[0]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isi && judul && sumber && idAdmin && dateTime && foto) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      formData.append('photo', foto);

      formData.append('isi', isi);
      formData.append('judul', judul);
      formData.append('sumber', sumber);
      formData.append('idAdmin', 'idAdmin');
      formData.append('dateTime', new Date());
      formData.append('idAdmin', id);

      const response = await axios.post(POST_NOTIFIKASI, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Notifikasi berhasil ditambahkan', 'success');
        history.push('/admin/notifikasi');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/notifikasi' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tambah Notifikasi</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Berita (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Judul Notifikasi</label>
                      <Input
                        type='text'
                        value={judul}
                        name='nama'
                        onChange={(event) => setJudul(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup></FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Sumber</label>
                          <Input
                            type='text'
                            value={sumber}
                            name='harga'
                            onChange={(event) => setSumber(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup></FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Sumber</label>
                          <Input
                            type='text'
                            value={sumber}
                            name='jenis'
                            onChange={(event) => setSumber(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Ready</label>
                          <Input type='select' name='ready' value=''>
                            <option value={true}>Ada</option>
                            <option value={false}>Kosong</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Isi Notifikasi</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={isi}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setIsi(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
