import { GET_LIST_TOKO } from '../../actions/TokoAction';

const initialState = {
  getTokoLoading: false,
  getTokoResult: false,
  getTokoError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_TOKO:
      return {
        ...state,
        getTokoLoading: action.payload.loading,
        getTokoResult: action.payload.data,
        getTokoError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
