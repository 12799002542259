import { GET_LIST_FASILITAS_DESTINASI } from '../../actions/FasilitasDestinasiAction';

const initialState = {
  getListFasilitasDestinasiLoading: false,
  getListFasilitasDestinasiResult: false,
  getListFasilitasDestinasiError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_FASILITAS_DESTINASI:
      return {
        ...state,
        getListFasilitasDestinasiLoading: action.payload.loading,
        getListFasilitasDestinasiResult: action.payload.data,
        getListFasilitasDestinasiError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
