/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, useLocation } from 'react-router-dom';

import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import routes from 'routes.js';
import { connect } from 'react-redux';
import { checkLogin } from 'actions/AuthAction';
import { GET_TOKEN } from 'utils';
import { logOutUser } from 'actions/AuthAction';
import { useDispatch } from 'react-redux';

var ps;

function Dashboard(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [backgroundColor, setBackgroundColor] = React.useState('white');
  const [activeColor, setActiveColor] = React.useState('info');
  const mainPanel = React.useRef();
  const location = useLocation();

  //const aksesKu = 'Dashboard,Berita,Users';

  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState([]);
  const [status, setStatus] = useState('');
  const [token, setToken] = useState('');
  const [idAdmin, setIdAdmin] = useState('');
  const [expire, setExpire] = useState('');
  const [routesKu, setRoutesKu] = useState([]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      if (decoded.akses) {
        setAkses(() => decoded.akses.split(','));
      } else {
        setAkses(['Dashboard']);
      }

      setStatus(decoded.status);

      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(props.history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    refreshToken();
  }, []);

  useEffect(() => {
    if (status == 'super admin') {
      setRoutesKu(routes);
    } else if (status == 'admin') {
      var routesTemp = [];
      routes.map((item) => {
        var filterRoutes = akses.filter((fil) => {
          return fil == item.menu;
        });

        if (item.menu == 'User Account') {
          routesTemp.push(item);
        }

        if (filterRoutes.length > 0) {
          routesTemp.push(item);
        }
      });

      setRoutesKu(routesTemp);
    }
  }, [status]);

  React.useEffect(() => {
    props.dispatch(checkLogin(props.history));

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <div className='wrapper'>
      <Sidebar
        {...props}
        routes={routesKu}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className='main-panel' ref={mainPanel}>
        <DemoNavbar {...props} username={username} idAdmin={idAdmin} />
        <Switch>
          {routesKu.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
                exact
              />
            );
          })}
        </Switch>
        <Footer fluid />
      </div>
    </div>
  );
}

export default connect()(Dashboard);
