import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import { GET_PESANAN_DETAIL } from 'utils';
import axios from 'axios';
import { EDIT_PESANAN } from 'utils';

import { getUser } from '../../actions/GetUserAction';

export default function EditPesananMasuk() {
  const { id } = useParams();
  const idKu = id.split(',');
  const history = useHistory();

  const dispatch = useDispatch();

  const { getUserError, getUserResult, getUserLoading } = useSelector(
    (state) => state.GetUserReducer
  );

  const [toko, setToko] = useState('');
  const [idUser, setidUser] = useState('');

  const [produk, setProduk] = useState('');
  const [totalHarga, setTotalHarga] = useState('');

  const [jumlah, setJumlah] = useState('');

  const [status, setStatus] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [ukuran, setUkuran] = useState('');
  const [totalBerat, setTotalBerat] = useState('');
  const [ekspedisi, setEkspedisi] = useState('');
  const [pemesan, setPemesan] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const [urlFile1, setUrlFile1] = useState('');

  useEffect(() => {
    getDetailPesanan();
  }, [id]);

  useEffect(() => {
    if (idUser) {
      dispatch(getUser(idUser));
    }
  }, [idUser]);

  useEffect(() => {
    if (getUserResult) {
      setPemesan(getUserResult);
    }
  }, [getUserResult]);

  const getDetailPesanan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_PESANAN_DETAIL + `${idKu[0]}`);
      data1 = response.data.data[0];

      if (data1) {
        setUrlFile1(data1.gambar.split(',')[0]);

        setToko(data1.toko);
        setidUser(data1.idUser);

        setProduk(data1.produk);
        setJumlah(data1.jumlah);
        setTotalHarga(data1.totalHarga);
        setStatus(data1.status);
        setKeterangan(data1.keterangan);
        setUkuran(data1.ukuran);
        setTotalBerat(data1.totalBerat);
        setEkspedisi(data1.ekspedisi);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (toko && status) {
      var dataKu = {
        gambar: urlFile1,
        toko: toko,
        idUser: idUser,
        produk: produk,
        jumlah: jumlah,
        totalHarga: totalHarga,
        status: status,
        totalBerat: totalBerat,
        ukuran: ukuran,
        ekspedisi: ekspedisi,
        keterangan: keterangan,
        idAdmin: idKu[1],
      };

      const response = await axios.patch(EDIT_PESANAN + `${idKu[0]}`, dataKu);

      if (response.data.success === 1) {
        swal('Success', 'Pesanan berhasil diupdate', 'success');
        history.push('/admin/pesananMasuk');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/pesananMasuk' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Pesanan Masuk</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                      </Col>
                      <Col>
                        <Row>
                          <h6>Pemesan</h6>
                        </Row>
                        {getUserResult ? (
                          <div>
                            <Row>
                              <h7>{pemesan.nama}</h7>
                            </Row>
                            <Row>
                              <h7>{pemesan.alamat}</h7>
                            </Row>
                            <Row>
                              <h7>{pemesan.nohp}</h7>
                            </Row>
                          </div>
                        ) : getUserLoading ? (
                          <div colSpan='6' align='center'>
                            <Spinner color='primary' />
                          </div>
                        ) : getUserError ? (
                          <div colSpan='6' align='center'>
                            {getUserError}
                          </div>
                        ) : (
                          <div colSpan='6' align='center'>
                            Data Kosong
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama Toko</label>
                      <Input
                        type='text'
                        value={toko.split('##')[0]}
                        name='nama'
                        disabled
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nomor HP</label>
                          <Input
                            type='text'
                            value={produk.split(',')[0]}
                            name='jenis'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>jumlah</label>
                          <Input
                            type='text'
                            value={jumlah}
                            name='harga'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Total harga</label>
                          <Input
                            type='tex'
                            style={{
                              resize: 'vertical',
                            }}
                            value={totalHarga}
                            name='text'
                            id='exampleText'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Total Berat</label>
                          <Input
                            type='text'
                            value={totalBerat}
                            name='jenis'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Keterangan</label>
                          <Input
                            type='tex'
                            style={{
                              resize: 'vertical',
                            }}
                            value={keterangan}
                            name='text'
                            id='exampleText'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Cara Pengambilan</label>
                          <Input
                            type='text'
                            value={ekspedisi}
                            name='jenis'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status Pemesanan</label>
                          <Input
                            type='select'
                            name='liga'
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='pesan'>pesan</option>
                            <option value='barang siap diambil'>
                              barang siap diambil
                            </option>
                            <option value='selesai'>selesai</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
