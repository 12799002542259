import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import axios from 'axios';
import { POST_VIDEO, GET_TOKEN } from 'utils';

export default function TambahVideo() {
  const history = useHistory();
  const { id } = useParams();

  const [gambar, setGambar] = useState('');

  const [judul, setJudul] = useState('');
  const [sumber, setSumber] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [link, setLink] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const [urlFile1, setUrlFile1] = useState(DefaultImage);

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/video');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
  }, []);
  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (judul && gambar && sumber && tanggal && link) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      formData.append('photo', gambar);
      formData.append('judul', judul);
      formData.append('sumber', sumber);
      formData.append('tanggal', tanggal);
      formData.append('link', link);
      formData.append('idAdmin', id);

      const response = await axios.post(POST_VIDEO, formData, {
        credentials: 'include',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Video berhasil ditambahkan', 'success');
        history.push('/admin/video');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/video' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tambah Video Youtube</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Thumbnail Video</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Judul Video</label>
                      <Input
                        type='text'
                        value={judul}
                        name='nama'
                        onChange={(event) => setJudul(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Sumber</label>
                          <Input
                            type='select'
                            name='liga'
                            value={sumber}
                            onChange={(event) => setSumber(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='Youtube DKISP'>Youtube DKISP</option>
                            <option value='Youtube Pemda'>Youtube Pemda</option>
                            <option value='Youtube Disbudpar'>
                              Youtube Disbudpar
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Tanggal</label>
                          <Input
                            type='date'
                            value={tanggal}
                            name='harga'
                            onChange={(event) => setTanggal(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <label>Link Video</label>
                          <Input
                            type='text'
                            value={link}
                            name='berat'
                            onChange={(event) => setLink(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Ready</label>
                          <Input type='select' name='ready' value=''>
                            <option value={true}>Ada</option>
                            <option value={false}>Kosong</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
