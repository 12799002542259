import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import { GET_PASARTERNAK_DETAIL, EDIT_PASARTERNAK } from 'utils';
import axios from 'axios';
import { EDIT_LAPORAN, GET_TOKEN } from 'utils';

export default function EditPasarTernak() {
  const { id } = useParams();
  const idKu = id.split(',');
  const history = useHistory();

  const [isi, setIsi] = useState('');
  const [umur, setUmur] = useState('');
  const [idUser, setIdUser] = useState('');
  const [statusVerifikasi, setStatusVerifikasi] = useState('');
  const [desa, setDesa] = useState('');
  const [kategori, setKategori] = useState('');
  const [harga, setHarga] = useState('');
  const [noHp, setNoHp] = useState('');
  const [satuan, setSatuan] = useState('');
  const [status, setStatus] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [nik, setNik] = useState('');

  const [saveLoading, setSaveLoading] = useState(false);

  const [listInstansi, setListInstansi] = useState([]);
  const [urlFile1, setUrlFile1] = useState('');
  const [urlFile2, setUrlFile2] = useState('');

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        // history.push('/admin/pasarternak');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();

    getDetailPasarTernak();
  }, [id]);

  const getDetailPasarTernak = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_PASARTERNAK_DETAIL + `${idKu[0]}`);
      data1 = response.data.data[0];

      if (data1) {
        setUrlFile1(data1.foto.split(',')[0]);
        setUrlFile2(data1.foto.split(',')[1]);
        setIsi(data1.isi);
        setKategori(data1.kategori);
        setTanggal(data1.tanggal);
        setUmur(data1.umur);
        setStatusVerifikasi(data1.statusVerifikasi);
        setDesa(data1.desa);
        setStatus(data1.status);
        setNoHp(data1.nohp);
        setHarga(data1.harga);
        setSatuan(data1.satuan);
        setIdUser(data1.idUser.split('##')[1]);
        setNik(data1.nik);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
  }

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (kategori && status) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      let data = {};
      data.statusVerifikasi = statusVerifikasi;
      data.status = status;
      data.idAdmin = idKu[1];

      console.log(data);

      const response = await axios.patch(
        EDIT_PASARTERNAK + `${idKu[0]}`,
        data,
        {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.success === 1) {
        swal('Success', 'postingan pasar ternak berhasil diedit', 'success');
        setSaveLoading(false);
        history.push('/admin/pasarternak');
      } else {
        setSaveLoading(false);
        swal('Failed', 'Penyimpanan gagal', 'error');
      }
    } else {
      setSaveLoading(false);
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/pasarternak' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Postingan Pasar Ternak</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto pasar ternak (satu)'
                        />
                      </Col>
                      <Col>
                        <img
                          src={urlFile2}
                          width='300'
                          alt='Foto pasar ternak (dua)'
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>Kategori</label>
                          <Input type='text' value={kategori} name='nama' />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label>NIK</label>
                          <Input type='text' value={nik} name='nama' />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status Verifikasi</label>
                          <Input
                            type='select'
                            name='liga'
                            value={statusVerifikasi}
                            onChange={(event) =>
                              setStatusVerifikasi(event.target.value)
                            }
                          >
                            <option value=''>--Pilih--</option>
                            <option value='none'>none</option>
                            <option value='terverifikasi'>terverifikasi</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Tanggal Postingan</label>
                          <Input type='text' value={tanggal} name='harga' />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Umur</label>
                          <Input type='text' name='liga' value={umur} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Desa</label>
                          <Input type='text' value={desa} name='jenis' />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status ketersediaan</label>
                          <Input
                            type='select'
                            name='ready'
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value='ready'> ready </option>
                            <option value='terbeli'> terbeli </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nomor Handphone</label>
                          <Input type='text' name='ready' value={noHp} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>User </label>
                          <Input type='text' name='ready' value={idUser} />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Harga</label>
                          <Input type='text' name='ready' value={harga} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Isi postingan</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={isi}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setIsi(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
