import axios from 'axios';
import Compressor from 'compressorjs';

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dateTime = (waktu) => {
  const hari = {
    0: 'Minggu',
    1: 'Senin',
    2: 'Selasa',
    3: 'Rabu',
    4: 'Kamis',
    5: 'Jumat',
    6: 'Sabtu',
  };

  const bulan = {
    0: 'Januari',
    1: 'Februari',
    2: 'Maret',
    3: 'April',
    4: 'Mei',
    5: 'Juni',
    6: 'Juli',
    7: 'Agustus',
    8: 'September',
    9: 'Oktober',
    10: 'November',
    11: 'Desember',
  };

  const bulan2 = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'Mei',
    5: 'Jun',
    6: 'Jul',
    7: 'Agu',
    8: 'Sep',
    9: 'Okt',
    10: 'Nov',
    11: 'Des',
  };
  var tanggal = new Date(waktu);
  var month = bulan2[tanggal.getMonth()];
  var day = hari[tanggal.getDay()];

  var date = tanggal.getDate().toString();
  if (date.length == 1) {
    date = '0' + date;
  }
  var year = tanggal.getFullYear();
  var jam = tanggal.getHours().toString();

  if (jam.length == 1) {
    jam = '0' + jam;
  }

  var minutes = tanggal.getMinutes().toString();
  if (minutes.length == 1) {
    minutes = '0' + minutes;
  }
  var waktukKu = {};
  waktukKu.tanggalLengkap = date + ' ' + month + ' ' + year;
  waktukKu.pukul = jam + ':' + minutes;
  waktukKu.hari = day;
  return waktukKu;
};

export const substractDates = (date) => {
  var now = new Date();
  var tanggal = now.getDate();
  var bulan = now.getMonth() + 1;
  var tahun = now.getFullYear();
  var d1 = new Date(bulan + '/' + tanggal + '/' + tahun);
  var dateKu = new Date(date);
  var days = Math.floor(Math.abs(d1 - dateKu) / 1000 / 60 / 60 / 24);

  var keterangan = '';

  if (days < 1) {
    keterangan = 'hari ini';
  } else if (days > 0 && days < 8) {
    keterangan = days + ' hari lalu';
  } else {
    var waktu = dateTime(date);
    keterangan = waktu.tanggalLengkap;
  }

  var result = {};
  result.days = days;
  result.keterangan = keterangan;

  return result;
};

export const kirimNotif = (data) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      'key=AAAAE1tz7tU:APA91bEvy_eqPOYU4mD0ZTLQCymABH63-ncFd7l9VCfG1P1pHrDxFwKUQmUGcBBFJPn_5lxPB281VePQe53vw_pSQcD6jOznoG6-yi5FOYtuZB1tXxQjhvKxNWQMv2k828XLbnvL4C_1',
  };

  axios
    .post('https://fcm.googleapis.com/fcm/send', data, {
      headers: headers,
    })
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
};

export const kompresImage = (img, setGambar) => {
  new Compressor(img, {
    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
    success: (compressedResult) => {
      // compressedResult has the compressed file.
      // Use the compressed file to upload the images to your server.
      setGambar(compressedResult);
    },
  });
};
