import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import swal from 'sweetalert';
import { kirimNotif } from 'utils';
import axios from 'axios';
import {
  UPDATE_ANGKUTSAMPAH,
  POST_PROGRES_ANGKUTSAMPAH,
  GET_PROGRES_ANGKUTSAMPAH,
  GET_ANGKUTSAMPAH_DETAIL,
  POST_NOTIFIKASI_PRIBADI,
} from 'utils';

import { getUser } from '../../actions/GetUserAction';
import { dateTime, substractDates } from 'utils';
import { get } from 'jquery';

export default function EditAngkutSampah() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const { getUserError, getUserResult, getUserLoading } = useSelector(
    (state) => state.GetUserReducer
  );

  const progressAS = [
    {
      id: -1,
      value: '',
    },
    {
      id: 0,
      value: 'Pesanan Dikonfirmasi',
    },
    {
      id: 1,
      value: 'Pengangkutan Terjadwal',
    },
    {
      id: 2,
      value: 'Pengangkutan Sementara Berlangsung',
    },
    {
      id: 3,
      value: 'Selesai',
    },
  ];

  const [tanggal, setTanggal] = useState('');
  const [idUser, setidUser] = useState('');

  const [jenis, setJenis] = useState('');
  const [waktuAngkut, setWaktuAngkut] = useState('');
  const [progresAngkut, setProgresAngkut] = useState(progressAS);

  const [jumlah, setJumlah] = useState('');

  const [idAdmin, setIdAdmin] = useState('');

  const [statusPengangkutan, setStatusPengangkutan] = useState('');
  const [statusPengakutaLama, setStatusPengakutaLama] = useState('');
  const [status, setStatus] = useState('');
  const [koordinat, setKoordinat] = useState('');
  const [ukuran, setUkuran] = useState('');
  const [petugas, setPetugas] = useState('');
  const [harga, setHarga] = useState('');
  const [nama, setNama] = useState('');
  const [statusBayar, setStatusBayar] = useState('');
  const [rating, setRating] = useState('');
  const [alamat, setAlamat] = useState('');
  const [waktu, setWaktu] = useState('');
  const [progres, setProgres] = useState([]);
  const [fcmToken, setFcmToken] = useState('');

  useEffect(() => {
    if (idUser) {
      dispatch(getUser(idUser));
    }
  }, [idUser]);

  useEffect(() => {
    if (getUserResult) {
      setFcmToken(getUserResult.fcmToken ? getUserResult.fcmToken : '');
    }
  }, [getUserResult]);

  useEffect(() => {
    getDetailAngkutSampah();
    getProgresAngkutSampah();
  }, [id]);

  const getProgresAngkutSampah = async () => {
    let data1 = [];
    try {
      const response = await axios.get(GET_PROGRES_ANGKUTSAMPAH + `${id}`);
      data1 = response.data.data;

      if (data1) {
        setProgres(data1);
        let indexProgress = data1.length - 1;

        let filter = progressAS.filter((item) => {
          return item.id >= indexProgress;
        });
        setProgresAngkut(filter);
        setStatusPengangkutan(data1[data1.length - 1].status);
        setStatusPengakutaLama(data1[data1.length - 1].status);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getDetailAngkutSampah = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_ANGKUTSAMPAH_DETAIL + `${id}`);
      data1 = response.data.data[0];

      if (data1) {
        setJenis(data1.jenis);
        setidUser(data1.idUser);
        setWaktu(substractDates(data1.tanggal));

        setTanggal(data1.tanggal);
        setUkuran(data1.ukuran);
        setWaktuAngkut(data1.waktuAngkut);
        setJumlah(data1.jumlah);
        setStatus(data1.status);
        setKoordinat(data1.koordinat);
        setPetugas(data1.petugas);
        setHarga(data1.harga);
        setStatusBayar(data1.statusBayar);
        setRating(data1.rating);
        setAlamat(data1.alamat);
        setNama(data1.nama);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const index1 = progressAS.filter((item) => {
      return item.value == statusPengakutaLama;
    });
    const index2 = progressAS.filter((item) => {
      return item.value == statusPengangkutan;
    });

    const selisih = index2[0].id - index1[0].id;

    if (statusPengangkutan && status) {
      if (selisih === 1) {
        var dataKu = {
          jenis: jenis,
          idUser: idUser,

          tanggal: tanggal,
          ukuran: ukuran,
          waktuAngkut: waktuAngkut,
          jumlah: jumlah,
          status: statusPengangkutan == 'Selesai' ? 'T' : status,
          koordinat: koordinat,
          petugas: petugas,
          harga: harga,
          statusBayar: statusBayar,
          rating: rating,
          alamat: alamat,
          nama: nama,
        };

        const response = await axios.patch(
          UPDATE_ANGKUTSAMPAH + `${id}`,
          dataKu
        );
        if (response.data.success === 1) {
          //mengecek apakah ada perubahan status pengangkutan agar tidak terjadi pengulangan

          postProgresAngkut();
        }
      } else {
        swal(
          'Info',
          'Periksa kembali status pengangkutan. Tidak bisa sama dan harus mengikuti urutan',
          'info'
        );
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
  };

  const postNotifPribadi = async (data) => {
    let dataKu = {};
    dataKu.dateTime = new Date();
    dataKu.sumber = 'Angkut Sampah';
    dataKu.isi =
      'status pesanan angkut sampah anda adalah : ' + statusPengangkutan;
    dataKu.judul = 'Pesanan Angkut Sampah';
    dataKu.idAdmin = 'idAdmin';
    dataKu.idUser = idUser;

    await axios.post(POST_NOTIFIKASI_PRIBADI, dataKu);
  };

  const postProgresAngkut = async () => {
    const data = {
      to: fcmToken,
      notification: {
        title: 'Angkut sampah',
        body:
          'status pesanan angkut sampah anda adalah : ' + statusPengangkutan,
        mutable_content: true,
        sound: 'Tri-tone',
      },

      data: {
        url: 'https://mmc.kalteng.go.id/files/berita/22052018090856_0.jpg',
        dl: 'AngkutSampah',
      },
    };

    if (statusPengangkutan !== 'Pengangkutan Terjadwal') {
      let dataProgres = {
        idAngkutSampah: id,
        dateTime: new Date(),
        status: statusPengangkutan,
        keterangan: '',
        waktuAngkut: waktuAngkut,
        foto: '',
      };

      const response = await axios.post(POST_PROGRES_ANGKUTSAMPAH, dataProgres);
      if (response.data.success === 1) {
        swal('Success', 'Pesanan berhasil diupdate', 'success');
        if (fcmToken) {
          kirimNotif(data);
          postNotifPribadi(dataProgres);
        }
        history.push('/admin/angkutsampah');
      }
    } else {
      if (waktuAngkut && petugas) {
        let dataProgres = {
          idAngkutSampah: id,
          dateTime: new Date(),
          status: statusPengangkutan,
          keterangan: '',
          waktuAngkut: '',
          foto: '',
        };
        const response = await axios.post(
          POST_PROGRES_ANGKUTSAMPAH,
          dataProgres
        );
        if (response.data.success === 1) {
          swal('Success', 'Pesanan berhasil diupdate', 'success');
          if (fcmToken) {
            kirimNotif(data);
            postNotifPribadi(dataProgres);
          }
          history.push('/admin/angkutsampah');
        }
      } else {
        swal('Info', 'Waktu angkut dan petugas harus diisi', 'info');
      }
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/angkutSampah' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tindak Lanjut Pesanan Masuk</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <div>
                          <Row>
                            <Col md={6}>
                              <h7>Tanggal Pesanan</h7>
                            </Col>
                            <Col Col md={6}>
                              <h7
                                style={{
                                  backgroundColor: 'green',
                                  padding: 5,
                                  color: 'white',
                                  fontWeight: '400',
                                  borderRadius: 3,
                                }}
                              >
                                {waktu.keterangan}
                              </h7>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <h7>{nama.split('##')[0]}</h7>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h7>{alamat}</h7>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h7>{nama.split('##')[1]}</h7>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup style={{ marginTop: 20 }}>
                          <label>Progres Pengangkutan</label>

                          {progres.map((item) => {
                            let waktu = dateTime(item.dateTime);
                            return (
                              <Row style={{ marginLeft: 20 }}>
                                <Col>
                                  <label> {item.status}</label>
                                </Col>
                                <Col>
                                  <label>
                                    {' '}
                                    {waktu.tanggalLengkap}, {waktu.pukul}{' '}
                                  </label>
                                </Col>
                              </Row>
                            );
                          })}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup style={{ marginTop: 20 }}>
                          <label>Status Pengangkutan</label>
                          <Input
                            type='select'
                            name='liga'
                            value={statusPengangkutan}
                            onChange={(event) => {
                              setStatusPengangkutan(event.target.value);
                            }}
                            required
                          >
                            <option value=''>--Pilih--</option>
                            {progresAngkut.map((item) => {
                              return (
                                <option value={item.value}>{item.value}</option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Jenis sampah</label>
                      <Input type='text' value={jenis} name='nama' disabled />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Alat angkut</label>
                          <Input
                            type='text'
                            value={ukuran}
                            name='jenis'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>jumlah</label>
                          <Input
                            type='text'
                            value={jumlah}
                            name='harga'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label> harga</label>
                          <Input
                            type='tex'
                            style={{
                              resize: 'vertical',
                            }}
                            value={harga}
                            name='text'
                            id='exampleText'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Waktu angkut</label>
                          <Input
                            type='date'
                            value={waktuAngkut}
                            name='jenis'
                            onChange={(e) => {
                              setWaktuAngkut(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Koordinat</label>
                          <Input
                            type='tex'
                            style={{
                              resize: 'vertical',
                            }}
                            value={koordinat}
                            name='text'
                            id='exampleText'
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Petugas angkut</label>

                          <Input
                            type='select'
                            name='liga'
                            value={petugas}
                            onChange={(event) => setPetugas(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='Siprianus Mony'>
                              Siprianus Mony
                            </option>
                            <option value='Noak Boik'>Noak Boik</option>
                            <option value='Gustan Bessie'>Gustan Bessie</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status Bayar</label>
                          <Input
                            type='select'
                            name='liga'
                            value={statusBayar}
                            onChange={(event) =>
                              setStatusBayar(event.target.value)
                            }
                          >
                            <option value=''>--Pilih--</option>
                            <option value='pending'>pending</option>
                            <option value='lunas'>Lunas </option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status Pesanan</label>
                          <Input
                            type='select'
                            name='liga'
                            value={status}
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            <option value='D'>Dalam Prosess</option>
                            <option value='B'>Batal </option>
                            <option value='T'>Terangkut </option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      type='submit'
                      color='danger'
                      className='ml-2'
                      disabled={getUserLoading ? true : false}
                    >
                      <i className='nc-icon nc-basket'></i> Simpan
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
