import { GET_LIST_LAPORAN } from '../../actions/AfadaAction';

const initialState = {
  getListLaporanLoading: false,
  getListLaporanResult: false,
  getListLaporanError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_LAPORAN:
      return {
        ...state,
        getListLaporanLoading: action.payload.loading,
        getListLaporanResult: action.payload.data,
        getListLaporanError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
