import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';

import { updateProfile } from 'actions/AuthAction';

import axios from 'axios';
import { GET_TOKEN, GET_INSTANSI } from 'utils';
import { getUserDHO } from 'actions/GetUserAction';

export default function EditSmartUser() {
  const { id } = useParams();
  const idKu = id.split(',');

  const dispatch = useDispatch();

  const { getUserError, getUserResult, getUserLoading } = useSelector(
    (state) => state.GetUserReducer
  );

  const { updateLoading, updateResult } = useSelector(
    (state) => state.AuthReducer
  );

  const history = useHistory();

  const [instansi, setInstansi] = useState('');
  const [nama, setNama] = useState('');
  const [waktuMasuk, setWaktuMasuk] = useState('');

  const [waktuPulang, setWaktuPulang] = useState('');
  const [status, setStatus] = useState('');
  const [avatar, setAvatar] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [listInstansi, setListInstansi] = useState([]);

  const [alamat, setAlamat] = useState('');
  const [desa, setDesa] = useState('');
  const [domisili, setDomisili] = useState('');
  const [email, setEmail] = useState('');
  const [fcmToken, setFcmToken] = useState('');
  const [gender, setGender] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [namaPengguna, setNamaPengguna] = useState('');
  const [uid, setUid] = useState('');

  const [nik, setNik] = useState('');

  const [nip, setNip] = useState('');
  const [nohp, setNohp] = useState('');
  const [pangkat, setPangkat] = useState('');
  const [statusRegister, setStatusRegister] = useState('');
  const [token, setToken] = useState('');
  const [unitKerja, setUnitKerja] = useState('');
  const [date, setDate] = useState('');
  const [dob, setDob] = useState('');
  const [provinsi, setProvinsi] = useState('');
  const [kota, setKota] = useState('');

  const [edit, setEdit] = useState(false);

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/smartuser');
      }
    }
  };
  //auth

  const getListInstansi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_INSTANSI);
      data1 = response.data.data;

      if (data1.length > 0) {
        const dataInstansi = data1.map((item) => {
          return item.unitKerja;
        });
        setListInstansi(dataInstansi);
      } else {
        setListInstansi([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    refreshToken();
    getListInstansi();
    var today = new Date();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    setWaktuMasuk(time);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUserDHO(idKu[0]));
    }
  }, [id]);
  useEffect(() => {
    if (getUserResult) {
      console.log(getUserResult);
      setNama(getUserResult.nama);

      setAvatar(getUserResult.avatar);
      setAlamat(getUserResult.alamat);

      setDomisili(getUserResult.domisili);
      setEmail(getUserResult.email);
      setFcmToken(getUserResult.fcmToken);
      setGender(getUserResult.gender);
      setUid(getUserResult.uid);

      setNamaPengguna(getUserResult.namaPengguna);
      setNik(getUserResult.nik);

      setNohp(getUserResult.nohp);

      setStatus(getUserResult.status);
      setStatusRegister(getUserResult.statusRegister);
      setToken(getUserResult.token);
      if (getUserResult.domisili == 'Kabupaten Rote Ndao') {
        setDesa(getUserResult.desa);
        setKecamatan(getUserResult.kecamatan);
      } else {
        setKota(getUserResult.kota);
        setProvinsi(getUserResult.provinsi);
      }
      if (getUserResult.statusRegister == 'ASN') {
        setPangkat(getUserResult.pangkat);
        setNip(getUserResult.nip);
        setInstansi(getUserResult.unitKerja);
      }
      if (getUserResult.dob) {
        var aa = getUserResult.dob.split('T');
        var bb = aa[0].split('-');
        console.log(bb);
        setDob(aa[0]);
      }
      if (getUserResult.date) {
        var aa = getUserResult.date.split('T');
        var bb = aa[0].split('-');
        console.log(bb);
        setDate(aa[0]);
      }
    }
  }, [getUserResult]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: 'Apakah anda yakin ?',
      text: 'Data yang sudah diedit tidak bisa dikembalikan !',
      icon: 'warning',
      buttons: ['Tidak, batalkan!', 'Ya, saya yakin!'],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        updateProfil(); // <--- submit form programmatically
      } else {
        swal('Cancelled', 'data profil tidak diupdate  :)', 'error');
      }
    });
  };

  const updateProfil = () => {
    setSaveLoading(true);

    // this.props.dispatch(updateJersey(this.state));

    const data = {
      uid: uid,
      nama: nama,
      alamat: alamat,
      nohp: nohp,
      kecamatan: kecamatan,
      desa: desa,
      email: email,
      pangkat: pangkat,
      unitKerja: instansi,
      nip: nip,
      status: status,
      avatar: avatar,
      domisili: domisili,
      statusRegister: statusRegister,
      provinsi: provinsi,
      kota: kota,
      dob: new Date(dob),
      date: new Date(date),
      gender: gender,
      fcmToken: fcmToken,
      token: token,
      nik: nik,
      namaPengguna: namaPengguna,
    };

    dispatch(updateProfile(data));

    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/smartuser' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Data Smart User</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama</label>
                      <Input type='text' value={nama} name='nama' />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nama pengguna</label>
                          <Input
                            type='text'
                            name='jenis'
                            value={namaPengguna}
                            onChange={(event) =>
                              setNamaPengguna(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>NIK</label>
                          <Input
                            type='text'
                            name='harga'
                            value={nik}
                            onChange={(event) => setNik(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label>Domisili</label>
                      <Input type='text' value={domisili} name='nama' />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Alamat</label>
                          <Input
                            type='text'
                            name='jenis'
                            value={alamat}
                            onChange={(event) => setAlamat(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>
                            {domisili == 'Kabupaten Rote Ndao'
                              ? 'Desa'
                              : 'Kota'}
                          </label>
                          <Input
                            type='text'
                            name='harga'
                            value={desa}
                            onChange={(event) => setDesa(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          {domisili == 'Kabupaten Rote Ndao'
                            ? 'Kecamatan'
                            : 'Provinsi'}{' '}
                          <Input
                            type='text'
                            name='jenis'
                            value={kecamatan}
                            onChange={(event) =>
                              setKecamatan(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>email</label>
                          <Input
                            type='text'
                            name='harga'
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Fcm Token</label>
                          <Input
                            type='text'
                            name='jenis'
                            value={fcmToken}
                            onChange={(event) =>
                              setFcmToken(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Token</label>
                          <Input
                            type='text'
                            name='harga'
                            value={token}
                            onChange={(event) => setToken(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status</label>
                          <Input
                            type='select'
                            value={status}
                            name='jenis'
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value={''}>- Pilih- </option>
                            <option value={'user'}>user</option>
                            <option value={'admin'}>admin</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <label>Status Registrasi</label>
                          <Input
                            type='select'
                            value={statusRegister}
                            name='jenis'
                            onChange={(event) =>
                              setStatusRegister(event.target.value)
                            }
                          >
                            <option value={''}>- Pilih- </option>
                            <option value={'ASN'}>ASN</option>
                            <option value={'Publik'}>Publik</option>
                          </Input>
                        </FormGroup>
                        {statusRegister == 'ASN' && (
                          <>
                            <FormGroup>
                              <label>Perangkat Daerah</label>
                              <Input
                                type='select'
                                value={instansi}
                                name='jenis'
                                onChange={(event) =>
                                  setInstansi(event.target.value)
                                }
                              >
                                {listInstansi.map((item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            <FormGroup>
                              <label>Pangkat</label>
                              <Input
                                type='text'
                                value={pangkat}
                                name='jenis'
                                onChange={(event) =>
                                  setPangkat(event.target.value)
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <label>NIP</label>
                              <Input
                                type='text'
                                value={nip}
                                name='jenis'
                                onChange={(event) => setNip(event.target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <label>Tanggal lahir</label>
                              <Input
                                type='date'
                                value={dob}
                                name='jenis'
                                onChange={(event) => setNip(event.target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <label>Tanggal Register</label>
                              <Input type='date' value={date} name='jenis' />
                            </FormGroup>
                          </>
                        )}
                      </Col>
                      <Col>
                        <img
                          src={avatar}
                          alt={'avatar'}
                          style={{
                            width: 200,
                            height: 200,
                            borderRadius: 100,
                            borderWidth: 8,
                            borderColor: 'red',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {!edit ? (
                    <Col>
                      {updateLoading ? (
                        <Button color='primary' type='submit' disabled>
                          <Spinner size='sm' color='light' /> Loading
                        </Button>
                      ) : (
                        <Button type='submit' color='danger' className='ml-2'>
                          <i className='nc-icon nc-basket'></i> Edit
                        </Button>
                      )}
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
