/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  Dashboard,
  ListBerita,
  EditBerita,
  TambahBerita,
  ListDestinasi,
  EditDestinasi,
  TambahDestinasi,
  EditProduk,
  ListProduk,
  TambahProduk,
  EditVideo,
  ListVideo,
  TambahVideo,
  ListAfada,
  EditAfada,
  ListFasilitasDestinasi,
  EditFasilitasDestinasi,
  TambahFasilitasDestinasi,
  EditNotifikasi,
  ListNotifikasi,
  TambahNotifikasi,
  ListToko,
  TambahToko,
  EditToko,
  ListPesananMasuk,
  EditPesananMasuk,
  ListServices,
  EditServices,
  TambahServices,
  ListAngkutSampah,
  EditAngkutSampah,
  ListBanner,
  TambahBanner,
  EditBanner,
  EditEmergency,
  ListEmergency,
  TambahEmergency,
  ListAdmin,
  TambahAdmin,
  EditAdmin,
  EditAdminUser,
  ListPasarTernak,
  EditPasarTernak,
  ListDaftarHadir,
  EditDaftarHadir,
  TambahDaftarHadir,
  ListSmartUser,
  EditSmartUser,
  TambahSmartUser,
  ListASNUser,
  TambahASNUser,
  EditASNUser,
  TambahAdminLayanan,
  EditAdminLayanan,
  ListAdminLayanan,
  ListLogApi,
} from './views';

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-bar-32',
    component: Dashboard,
    layout: '/admin',
    sidebar: true,
    menu: 'Dashboard',
  },

  {
    path: '/berita',
    name: 'Berita',
    icon: 'nc-icon nc-single-copy-04',
    component: ListBerita,
    layout: '/admin',
    sidebar: true,
    menu: 'Berita',
  },
  {
    path: '/berita/tambah/:id',
    name: 'Tambah Berita',
    component: TambahBerita,
    layout: '/admin',
    sidebar: false,
    menu: 'Berita',
  },
  {
    path: '/berita/edit/:id',
    name: 'Edit Berita',
    component: EditBerita,
    layout: '/admin',
    sidebar: false,
    menu: 'Berita',
  },
  {
    path: '/destinasi',
    name: 'Destinasi',
    icon: 'nc-icon nc-pin-3',
    component: ListDestinasi,
    layout: '/admin',
    sidebar: true,
    menu: 'Destinasi',
  },
  {
    path: '/destinasi/tambah/:id',
    name: 'Tambah Destinasi',
    component: TambahDestinasi,
    layout: '/admin',
    sidebar: false,
    menu: 'Destinasi',
  },
  {
    path: '/destinasi/edit/:id',
    name: 'Edit Destinasi',
    component: EditDestinasi,
    layout: '/admin',
    sidebar: false,
    menu: 'Destinasi',
  },
  {
    path: '/produk',
    name: 'Produk',
    icon: 'nc-icon nc-cart-simple',
    component: ListProduk,
    layout: '/admin',
    sidebar: true,
    menu: 'Produk',
  },
  {
    path: '/produk/tambah/:id',
    name: 'Tambah Produk',
    component: TambahProduk,
    layout: '/admin',
    sidebar: false,
    menu: 'Produk',
  },
  {
    path: '/produk/edit/:id',
    name: 'Edit Produk',
    component: EditProduk,
    layout: '/admin',
    sidebar: false,
    menu: 'Produk',
  },
  {
    path: '/video',
    name: 'Video',
    icon: 'nc-icon nc-button-play',
    component: ListVideo,
    layout: '/admin',
    sidebar: true,
    menu: 'Video',
  },
  {
    path: '/video/tambah/:id',
    name: 'Tambah Video',
    component: TambahVideo,
    layout: '/admin',
    sidebar: false,
    menu: 'Video',
  },
  {
    path: '/video/edit/:id',
    name: 'Edit Video',
    component: EditVideo,
    layout: '/admin',
    sidebar: false,
    menu: 'Video',
  },
  {
    path: '/afada',
    name: 'Forum RSS',
    icon: 'nc-icon nc-bell-55',
    component: ListAfada,
    layout: '/admin',
    sidebar: true,
    menu: 'Forum RSS',
  },
  {
    path: '/afada/edit/:id',
    name: 'Tindak Lanjut Forum RSS',
    component: EditAfada,
    layout: '/admin',
    sidebar: false,
    menu: 'Forum RSS',
  },
  {
    path: '/fasilitasDestinasi',
    name: 'Fasilitas Destinasi',
    icon: 'nc-icon nc-umbrella-13',
    component: ListFasilitasDestinasi,
    layout: '/admin',
    sidebar: true,
    menu: 'Fasilitas Destinasi',
  },
  {
    path: '/fasilitasDestinasi/tambah/:id',
    name: 'Tambah Fasilitas Destinasi',
    component: TambahFasilitasDestinasi,
    layout: '/admin',
    sidebar: false,
    menu: 'Fasilitas Destinasi',
  },
  {
    path: '/fasilitasDestinasi/edit/:id',
    name: 'Edit Fasilitas Destinasi',
    component: EditFasilitasDestinasi,
    layout: '/admin',
    sidebar: false,
    menu: 'Fasilitas Destinasi',
  },
  {
    path: '/notifikasi',
    name: 'Notifikasi',
    icon: 'nc-icon nc-bell-55',
    component: ListNotifikasi,
    layout: '/admin',
    sidebar: true,
    menu: 'Notifikasi',
  },
  {
    path: '/notifikasi/tambah/:id',
    name: 'Tambah Notifikasi',
    component: TambahNotifikasi,
    layout: '/admin',
    sidebar: false,
    menu: 'Notifikasi',
  },
  {
    path: '/notifikasi/edit/:id',
    name: 'Edit Notifikasi',
    component: EditNotifikasi,
    layout: '/admin',
    sidebar: false,
    menu: 'Notifikasi',
  },
  {
    path: '/toko',
    name: 'Toko',
    icon: 'nc-icon nc-bank',
    component: ListToko,
    layout: '/admin',
    sidebar: true,
    menu: 'Toko',
  },
  {
    path: '/toko/tambah/:id',
    name: 'Tambah Toko',
    component: TambahToko,
    layout: '/admin',
    sidebar: false,
    menu: 'Toko',
  },
  {
    path: '/toko/edit/:id',
    name: 'Edit Toko',
    component: EditToko,
    layout: '/admin',
    sidebar: false,
    menu: 'Toko',
  },
  {
    path: '/pesananMasuk',
    name: 'Pesanan masuk',
    icon: 'nc-icon nc-tag-content',
    component: ListPesananMasuk,
    layout: '/admin',
    sidebar: true,
    menu: 'Pesanan Masuk',
  },
  {
    path: '/pesananMasuk/edit/:id',
    name: 'Edit Pesanan Masuk',
    component: EditPesananMasuk,
    layout: '/admin',
    sidebar: false,
    menu: 'Pesanan Masuk',
  },
  {
    path: '/angkutSampah',
    name: 'Sampah',
    icon: 'nc-icon nc-check-2',
    component: ListAngkutSampah,
    layout: '/admin',
    sidebar: true,
    menu: 'Sampah',
  },
  {
    path: '/angkutSampah/edit/:id',
    name: 'Tindak Lanjut Angkut Sampah',
    component: EditAngkutSampah,
    layout: '/admin',
    sidebar: false,
    menu: 'Sampah',
  },
  {
    path: '/services',
    name: 'Layanan',
    icon: 'nc-icon nc-atom',
    component: ListServices,
    layout: '/admin',
    sidebar: true,
    menu: 'Layanan',
  },
  {
    path: '/services/edit/:id',
    name: 'Edit Layanan',
    component: EditServices,
    layout: '/admin',
    sidebar: false,
    menu: 'Layanan',
  },
  {
    path: '/services/tambah/:id',
    name: 'Tambah Layanan',
    component: TambahServices,
    layout: '/admin',
    sidebar: false,
    menu: 'Layanan',
  },
  {
    path: '/banner',
    name: 'Banner',
    icon: 'nc-icon nc-badge',
    component: ListBanner,
    layout: '/admin',
    sidebar: true,
    menu: 'Banner',
  },
  {
    path: '/banner/edit/:id',
    name: 'Edit banner',
    component: EditBanner,
    layout: '/admin',
    sidebar: false,
    menu: 'Banner',
  },
  {
    path: '/banner/tambah/:id',
    name: 'Tambah Banner',
    component: TambahBanner,
    layout: '/admin',
    sidebar: false,
    menu: 'Banner',
  },
  {
    path: '/emergency',
    name: 'Emergency Contact',
    icon: 'nc-icon nc-bulb-63',
    component: ListEmergency,
    layout: '/admin',
    sidebar: true,
    menu: 'Emergency Contact',
  },
  {
    path: '/emergency/edit/:id',
    name: 'Edit emergency contact',
    component: EditEmergency,
    layout: '/admin',
    sidebar: false,
    menu: 'Emergency Contact',
  },
  {
    path: '/emergency/tambah/:id',
    name: 'Tambah emergency contact',
    component: TambahEmergency,
    layout: '/admin',
    sidebar: false,
    menu: 'Emergency Contact',
  },
  {
    path: '/user',
    name: 'Users',
    icon: 'nc-icon nc-single-02',
    component: ListAdmin,
    layout: '/admin',
    sidebar: true,
    menu: 'Admin',
  },
  {
    path: '/user/edit/:id',
    name: 'Edit admin',
    component: EditAdmin,
    layout: '/admin',
    sidebar: false,
    menu: 'Admin',
  },
  {
    path: '/user/tambah/:id',
    name: 'Tambah admin',
    component: TambahAdmin,
    layout: '/admin',
    sidebar: false,
    menu: 'Admin',
  },
  {
    path: '/user/user/edit/:id',
    name: 'Edit admin user',
    component: EditAdminUser,
    layout: '/admin',
    sidebar: false,
    menu: 'User Account',
  },
  {
    path: '/pasarternak',
    name: 'Pasar Ternak',
    icon: 'nc-icon nc-compass-05',
    component: ListPasarTernak,
    layout: '/admin',
    sidebar: true,
    menu: 'Pasar Ternak',
  },
  {
    path: '/pasarternak/edit/:id',
    name: 'Edit Pasar Ternak',
    component: EditPasarTernak,
    layout: '/admin',
    sidebar: false,
    menu: 'Pasar Ternak',
  },
  {
    path: '/daftarhadir',
    name: 'Daftar Hadir',
    icon: 'nc-icon nc-single-copy-04',
    component: ListDaftarHadir,
    layout: '/admin',
    sidebar: true,
    menu: 'Daftar Hadir',
  },
  {
    path: '/daftarhadir/edit/:id',
    name: 'Edit Daftar Hadir',
    component: EditDaftarHadir,
    layout: '/admin',
    sidebar: false,
    menu: 'Daftar Hadir',
  },
  {
    path: '/daftarhadir/tambah',
    name: 'Tambah Daftar Hadir',
    component: TambahDaftarHadir,
    layout: '/admin',
    sidebar: false,
    menu: 'Daftar Hadir',
  },
  {
    path: '/smartuser',
    name: 'Smart User',
    icon: 'nc-icon nc-single-02',
    component: ListSmartUser,
    layout: '/admin',
    sidebar: true,
    menu: 'Smart User',
  },
  {
    path: '/smartuser/edit/:id',
    name: 'Edit Smart User',
    component: EditSmartUser,
    layout: '/admin',
    sidebar: false,
    menu: 'Smart User',
  },
  {
    path: '/smartuser/tambah',
    name: 'Tambah Smart User',
    component: TambahSmartUser,
    layout: '/admin',
    sidebar: false,
    menu: 'Smart User',
  },
  {
    path: '/ASNuser',
    name: 'ASN User',
    icon: 'nc-icon nc-single-02',
    component: ListASNUser,
    layout: '/admin',
    sidebar: true,
    menu: 'ASN User',
  },
  {
    path: '/ASNuser/edit/:id',
    name: 'Edit ASN User',
    component: EditASNUser,
    layout: '/admin',
    sidebar: false,
    menu: 'ASN User',
  },
  {
    path: '/ASNuser/tambah',
    name: 'Tambah ASN User',
    component: TambahASNUser,
    layout: '/admin',
    sidebar: false,
    menu: 'ASN User',
  },
  {
    path: '/adminlayanan',
    name: 'Admin Layanan',
    icon: 'nc-icon nc-single-02',
    component: ListAdminLayanan,
    layout: '/admin',
    sidebar: true,
    menu: 'Admin Layanan',
  },
  {
    path: '/adminlayanan/edit/:id',
    name: 'Edit Admin Layanan',
    component: EditAdminLayanan,
    layout: '/admin',
    sidebar: false,
    menu: 'Admin Layanan',
  },
  {
    path: '/adminlayanan/tambah',
    name: 'Tambah Admin Layanan',
    component: TambahAdminLayanan,
    layout: '/admin',
    sidebar: false,
    menu: 'Admin Layanan',
  },
  {
    path: '/logapi',
    name: 'LogApi',
    icon: 'nc-icon nc-tap-01',
    component: ListLogApi,
    layout: '/admin',
    sidebar: true,
    menu: 'LogAPI',
  },
];
export default routes;
