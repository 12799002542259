import { GET_LIST_BANNER } from '../../actions/BannerAction';

const initialState = {
  getBannerLoading: false,
  getBannerResult: false,
  getBannerError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_BANNER:
      return {
        ...state,
        getBannerLoading: action.payload.loading,
        getBannerResult: action.payload.data,
        getBannerError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
