import { GET_LIST_NOTIFIKASI } from '../../actions/NotifikasiAction';

const initialState = {
  getNotifikasiLoading: false,
  getNotifikasiResult: false,
  getNotifikasiError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_NOTIFIKASI:
      return {
        ...state,
        getNotifikasiLoading: action.payload.loading,
        getNotifikasiResult: action.payload.data,
        getNotifikasiError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
