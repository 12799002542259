import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { getPesananAll } from '../../actions/PesananMasukAction';
import { GET_TOKEN } from 'utils';
import { logOutUser } from 'actions/AuthAction';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export default function ListPesananMasuk() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    getListPesananAllError,
    getListPesananAllLoading,
    getListPesananAllResult,
  } = useSelector((state) => state.PesananAllReducer);

  // auth

  const [token, setToken] = useState('');
  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  useEffect(() => {
    dispatch(getPesananAll());
    refreshToken();
  }, []);

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>List Pesanan Masuk</CardTitle>
            </CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Id</th>
                    <th>Foto</th>
                    <th>Toko</th>
                    <th>Pemesan</th>
                    <th>Produk</th>
                    <th>Jumlah</th>
                    <th>Total Harga</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {getListPesananAllResult ? (
                    Object.keys(getListPesananAllResult).map((key) => (
                      <tr key={key}>
                        <td>{getListPesananAllResult[key].id}</td>
                        <td>
                          <img
                            src={
                              getListPesananAllResult[key].gambar.split(',')[0]
                            }
                            width='100'
                            alt={getListPesananAllResult[key].toko}
                          />
                        </td>
                        <td>
                          {getListPesananAllResult[key].toko.split('##')[0]}
                        </td>
                        <td>{getListPesananAllResult[key].status}</td>
                        <td>
                          {getListPesananAllResult[key].produk.split(',')[0]}{' '}
                        </td>
                        <td>{getListPesananAllResult[key].jumlah} </td>
                        <td>{getListPesananAllResult[key].totalHarga} </td>
                        <td>
                          <Link
                            className='btn btn-warning'
                            to={
                              '/admin/pesananMasuk/edit/' +
                              getListPesananAllResult[key].id +
                              ',' +
                              idAdmin
                            }
                          >
                            <i className='nc-icon nc-ruler-pencil'></i> Edit
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : getListPesananAllLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : getListPesananAllError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListPesananAllError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
