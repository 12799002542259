import React, { Component, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import DefaultImage from '../../assets/img/default-image.jpg';

import axios from 'axios';
import { POST_DESTINASI } from 'utils';
import { GET_KECAMATAN, GET_DESA, GET_TOKEN } from 'utils';

export default function TambahDestinasi() {
  const history = useHistory();
  const { id } = useParams();

  const [gambar, setGambar] = useState('');
  const [gambar2, setGambar2] = useState('');
  const [nama, setNama] = useState('');
  const [desa, setDesa] = useState('');
  const [kecamatan, setKecamatan] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  const [koordinat, setKoordinat] = useState('');
  const [listDesa, setListDesa] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listDesaPilihan, setListDesaPilihan] = useState([]);

  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [urlFile2, setUrlFile2] = useState(DefaultImage);
  const [saveLoading, setSaveLoading] = useState(false);

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
  }

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/destinasi');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
    getListDesa();
    getListKecamatan();
  }, []);

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
    setGambar2(e.target.files[0]);
  }

  const ubahKecamatan = (kecamatan) => {
    var listDesaPilih = listDesa.filter((item) => {
      return item.kecamatan == kecamatan;
    });

    var listDesaPilihFilter = listDesaPilih.map((item) => {
      return item.desa;
    });

    setListDesaPilihan(listDesaPilihFilter);
    setKecamatan(kecamatan);
  };

  const getListDesa = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_DESA);
      data1 = response.data.data;
      console.log(data1);
      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListKecamatan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_KECAMATAN);
      data1 = response.data.data;
      console.log(data1);
      if (data1.length > 0) {
        var dataku = data1.map((item) => {
          return item.kecamatan;
        });
        setListKecamatan(dataku);
      } else {
        setListKecamatan([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (
      nama &&
      desa &&
      kecamatan &&
      deskripsi &&
      koordinat &&
      gambar &&
      gambar2
    ) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      formData.append('photo', gambar);
      formData.append('photo', gambar2);

      formData.append('nama', nama);
      formData.append('desa', desa);
      formData.append('kecamatan', kecamatan);
      formData.append('deskripsi', deskripsi);
      formData.append('koordinat', koordinat);
      formData.append('idAdmin', id);

      const response = await axios.post(POST_DESTINASI, formData, {
        credentials: 'include',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Destinasi berhasil diinput', 'success');
        history.push('/admin/destinasi');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/destinasi' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Tambah Destinasi</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Destinasi (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={urlFile2}
                          width='300'
                          alt='Foto Destinasi (dua)'
                        />
                        <FormGroup>
                          <label>Foto Berita (dua)</label>
                          <Input
                            type='file'
                            name='image2'
                            accept={'image/*'}
                            onChange={(event) => onChange2(event, 'imageToDB2')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama Destinasi</label>
                      <Input
                        type='text'
                        value={nama}
                        name='nama'
                        onChange={(event) => setNama(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Kecamatan</label>
                          <Input
                            type='select'
                            value={kecamatan}
                            name='harga'
                            onChange={(event) =>
                              ubahKecamatan(event.target.value)
                            }
                          >
                            <option value=''>--Pilih--</option>
                            {listKecamatan.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Desa</label>
                          <Input
                            type='select'
                            name='liga'
                            value={desa}
                            onChange={(event) => setDesa(event.target.value)}
                          >
                            <option value=''>--Pilih--</option>
                            {listDesaPilihan.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Koordinat</label>
                          <Input
                            type='text'
                            value={koordinat}
                            name='berat'
                            onChange={(event) =>
                              setKoordinat(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col md={6}></Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Ready</label>
                          <Input type='select' name='ready' value=''>
                            <option value={true}>Ada</option>
                            <option value={false}>Kosong</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Deskripsi Destinasi</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={deskripsi}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setDeskripsi(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
