import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import { GET_EMERGENCY_DETAIL } from 'utils';
import axios from 'axios';
import { EDIT_EMERGENCY, GET_TOKEN } from 'utils';

export default function EditEmergency() {
  const { id } = useParams();
  const idKu = id.split(',');

  const history = useHistory();

  const [instansi, setInstansi] = useState('');
  const [layanan, setLayanan] = useState('');
  const [noHp, setNoHp] = useState('');

  const [telephone, setTelephone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/user');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
    getDetailEmergency();
  }, []);

  const getDetailEmergency = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_EMERGENCY_DETAIL + `${idKu[0]}`);
      data1 = response.data.data[0];

      if (data1) {
        setInstansi(data1.instansi);

        setLayanan(data1.layanan);
        setNoHp(data1.noHp);
        setTelephone(data1.telephone);

        setWhatsapp(data1.whatsapp);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (instansi && layanan && noHp && telephone && whatsapp) {
      //action
      // this.props.dispatch(updateJersey(this.state));
      let data = {
        instansi: instansi,
        layanan: layanan,
        noHp: noHp,
        telephone: telephone,
        whatsapp: whatsapp,
        idAdmin: idKu[1],
      };

      const response = await axios.patch(EDIT_EMERGENCY + `${idKu[0]}`, data, {
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Emergency Contact berhasil diedit', 'success');
        history.push('/admin/emergency');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/emergency' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Emergency</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label>Instansi</label>
                      <Input
                        type='text'
                        value={instansi}
                        name='nama'
                        onChange={(event) => setInstansi(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Layanan</label>
                          <Input
                            type='text'
                            value={layanan}
                            name='jenis'
                            onChange={(event) => setLayanan(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nomor Handphone</label>
                          <Input
                            type='text'
                            value={noHp}
                            name='harga'
                            onChange={(event) => setNoHp(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Layanan</label>
                          <Input
                            type='select'
                            value={telephone}
                            name='jenis'
                            onChange={(event) =>
                              setTelephone(event.target.value)
                            }
                          >
                            <option value={''}>--Pilih--</option>
                            <option value={'Ya'}>Ya</option>
                            <option value={'Tidak'}>Tidak</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nomor Handphone</label>
                          <Input
                            type='select'
                            value={whatsapp}
                            name='harga'
                            onChange={(event) =>
                              setWhatsapp(event.target.value)
                            }
                          >
                            <option value={''}>--Pilih--</option>
                            <option value={'Ya'}>Ya</option>
                            <option value={'Tidak'}>Tidak</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
