import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import { GET_FASILITASWISATA_DESTINASI_DETAIL, GET_DESTINASI } from 'utils';
import axios from 'axios';
import { EDIT_FASILITASWISATA } from 'utils';

export default function EditFasilitasDestinasi() {
  const { id } = useParams();
  const idKu = id.split(',');
  const history = useHistory();

  const [gambar, setGambar] = useState('');
  const [gambar2, setGambar2] = useState('');
  const [nama, setNama] = useState('');
  const [bintang, setBintang] = useState('');
  const [destinasi, setDestinasi] = useState('');
  const [deskripsi, setDeskripsi] = useState('');
  const [koordinat, setKoordinat] = useState('');
  const [phone, setPhone] = useState('');
  const [fasilitas, setFasilitas] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const [listDestinasi, setListDestinasi] = useState([]);

  const [urlFile1, setUrlFile1] = useState('');
  const [urlFile2, setUrlFile2] = useState('');
  const [fasilitasWisata, setfasilitasWisata] = useState([
    { item: 'Kamar', value: false },
    { item: 'Makanan', value: false },
    { item: 'Kopi', value: false },
    { item: 'Pisang Goreng', value: false },
    { item: 'Teh', value: false },
    { item: 'Ayam Geprek', value: false },
    { item: 'Kentang', value: false },
    { item: 'Bir', value: false },
  ]);

  const [checked, setChecked] = useState([]);

  const changeCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      let test = false;
      updatedList.map((item) => {
        if (item == event.target.value) {
          test = true;
        }
      });
      if (!test) {
        updatedList = [...checked, event.target.value];
      }
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
    setFasilitas(updatedList);
  };

  useEffect(() => {
    getDetailFasilitasWisata();
    getDestinasi();
  }, [id]);

  const getDestinasi = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_DESTINASI);
      data1 = response.data.data;
      let data2 = data1.map((item) => {
        return item.nama;
      });
      setListDestinasi(data2);

      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getDetailFasilitasWisata = async () => {
    var data1 = [];

    try {
      const response = await axios.get(
        GET_FASILITASWISATA_DESTINASI_DETAIL + `${idKu[0]}`
      );
      data1 = response.data.data[0];

      if (data1) {
        setUrlFile1(data1.gambar.split(',')[0]);
        setUrlFile2(data1.gambar.split(',')[1]);
        setNama(data1.nama);
        setBintang(data1.bintang);
        setDestinasi(data1.destinasi);
        setDeskripsi(data1.deskripsi);
        setKoordinat(data1.koordinat);
        setPhone(data1.phone);
        let fw = data1.fasilitas.split(',');

        setChecked(fw);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
  }

  function onChange2(e) {
    let uploaded = e.target.files[0];
    setUrlFile2(URL.createObjectURL(uploaded));
    setGambar2(e.target.files[0]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (
      nama &&
      bintang &&
      fasilitas &&
      deskripsi &&
      koordinat &&
      gambar &&
      gambar2
    ) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      formData.append('photo', gambar);
      formData.append('photo', gambar2);

      formData.append('nama', nama);
      formData.append('bintang', bintang);
      formData.append('deskripsi', deskripsi);
      formData.append('destinasi', destinasi);
      formData.append('koordinat', koordinat);
      formData.append('phone', phone);
      formData.append('fasilitas', fasilitas);
      formData.append('idAdmin', idKu[1]);

      const response = await axios.patch(
        EDIT_FASILITASWISATA + `${idKu[0]}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success === 1) {
        swal('Success', 'Fasilitas Destinasi berhasil diedit', 'success');
        history.push('/admin/fasilitasDestinasi');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/fasilitasDestinasi' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Fasilitas Destinasi</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto fasilitas (satu)'
                        />
                        <FormGroup>
                          <label>Foto Fasilitas Destinasi (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={urlFile2}
                          width='300'
                          alt='Foto Fasilitas Destinasi (dua)'
                        />
                        <FormGroup>
                          <label>Foto Fasilitas Destinasi (dua)</label>
                          <Input
                            type='file'
                            name='image2'
                            accept={'image/*'}
                            onChange={(event) => onChange2(event, 'imageToDB2')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama Fasilitas Destinasi</label>
                      <Input
                        type='text'
                        value={nama}
                        name='nama'
                        onChange={(event) => setNama(event.target.value)}
                      />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Destinasi</label>
                          <Input
                            type='select'
                            name='liga'
                            value={destinasi}
                            onChange={(event) =>
                              setDestinasi(event.target.value)
                            }
                          >
                            <option value=''>--Pilih--</option>
                            {listDestinasi.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Bintang</label>
                          <Input
                            type='number'
                            value={bintang}
                            name='harga'
                            onChange={(event) => setBintang(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Koordinat</label>
                          <Input
                            type='text'
                            value={koordinat}
                            name='berat'
                            onChange={(event) =>
                              setKoordinat(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <label>Phone</label>
                          <Input
                            type='text'
                            value={phone}
                            name='berat'
                            onChange={(event) => setPhone(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <label>
                            Ukuran Yang Tersedia Sekarang : (
                            {checked.map((item, index) => (
                              <strong key={index}> {item} </strong>
                            ))}
                            )
                          </label>
                        </Row>
                        <label>Fasilitas/menu tersedia</label>
                        <FormGroup check>
                          {fasilitasWisata.map((item, index) => (
                            <Label key={index} check className='mr-2'>
                              <Input
                                type='checkbox'
                                value={item.item}
                                onChange={(event) => changeCheck(event)}
                              />
                              {item.item}
                              <span className='form-check-sign'>
                                <span className='check'></span>
                              </span>
                            </Label>
                          ))}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Deskripsi Destinasi</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={deskripsi}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setDeskripsi(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
