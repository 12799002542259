import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';

import { GET_EMERGENCY_DETAIL } from 'utils';
import axios from 'axios';
import {
  EDIT_EMERGENCY,
  GET_TOKEN,
  API_DAFTARHADIR_CEK,
  API_TIMEOUT,
  API_DAFTARHADIR,
} from 'utils';
import { getUserDHO } from 'actions/GetUserAction';
import { catatMasuk } from 'actions/DaftarHadirAction';

export default function EditDaftarHadir() {
  const { id } = useParams();
  const idKu = id.split(',');

  const tanggalKu = idKu[2].split('-');
  const bulan = tanggalKu[1];
  const tahun = tanggalKu[0];

  const dispatch = useDispatch();

  const { getUserError, getUserResult, getUserLoading } = useSelector(
    (state) => state.GetUserReducer
  );

  const history = useHistory();

  const [instansi, setInstansi] = useState('');
  const [nama, setNama] = useState('');
  const [waktuMasuk, setWaktuMasuk] = useState('');

  const [waktuPulang, setWaktuPulang] = useState('');
  const [status, setStatus] = useState('');
  const [avatar, setAvatar] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [workingHours, setWorkingHours] = useState(0);
  const [idDH, setIdDH] = useState('');
  const [edit, setEdit] = useState(false);

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        history.push('/admin/daftarhadir');
      }
    }
  };
  //auth

  //edit masuk

  //catat masuk

  const catatMasuk = () => {
    var wM = '';
    if (waktuMasuk) {
      wM = waktuMasuk;
      wM = wM.split(':');
      wM = wM[0] + ':' + wM[1];
    }

    var wP = '';
    if (waktuPulang) {
      wP = waktuPulang;
      wP = wP.split(':');
      wP = wP[0] + ':' + wP[1];
    }
    axios({
      method: 'post',
      url: API_DAFTARHADIR,
      timeout: API_TIMEOUT,
      data: {
        tahun: tahun,
        bulan: bulan,
        tanggal: idKu[2],
        nama: nama,
        opd: instansi,
        waktuMasuk: wM,
        waktuPulang: wP,
        status: status,
        koordinatMasuk: '',
        koordinatPulang: '',
        workingHours: '',
        token: '',
        idUser: idKu[0],
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert('error');
        } else {
          //BERHASIL
          var dataKirim = {
            id: response.data.data.insertId,
            status: status,
          };

          swal(
            'Success',
            'Daftar Hadir atas nama ' +
              nama +
              ' berhasil dicatat dengan status ' +
              status,
            'success'
          );
          history.push('/admin/daftarhadir');
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);

        //alert(error);
      });
  };
  const editDH = () => {
    var wM = '';
    if (waktuMasuk) {
      wM = waktuMasuk;
      wM = wM.split(':');
      wM = wM[0] + ':' + wM[1];
    }

    var wP = '';
    if (waktuPulang) {
      wP = waktuPulang;
      wP = wP.split(':');
      wP = wP[0] + ':' + wP[1];
    }

    axios({
      method: 'post',
      url: API_DAFTARHADIR + '/' + 'pulang',
      timeout: API_TIMEOUT,
      data: {
        waktuMasuk: wM,
        waktuPulang: wP,
        status: status,
        koordinatPulang: '',

        workingHours: '',

        id: idDH,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert('error');
        } else {
          //BERHASIL

          swal(
            'Success',
            'Daftar Hadir atas nama ' +
              nama +
              ' berhasil diedit dengan status ' +
              status,
            'success'
          );
          history.push('/admin/daftarhadir');
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);

        //alert(error);
      });
  };

  //catatMasuk

  //ceck catatan masuk

  const cekCatatanMasuk = (data) => {
    axios({
      method: 'post',
      url: API_DAFTARHADIR_CEK,
      timeout: API_TIMEOUT,
      data: {
        tanggal: idKu[2],
        idUser: idKu[0],
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          const cekCatatanResult =
            response.data.data.length > 0 ? response.data.data[0] : [];

          if (cekCatatanResult.id) {
            setStatus(cekCatatanResult.status);

            setWaktuMasuk(cekCatatanResult.waktuMasuk);
            setWaktuPulang(cekCatatanResult.waktuPulang);
            setWorkingHours(cekCatatanResult.workingHours);
            setEdit(true);
            setIdDH(cekCatatanResult.id);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //aafadf

  useEffect(() => {
    cekCatatanMasuk();
    refreshToken();
    var today = new Date();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    setWaktuMasuk(time);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUserDHO(idKu[0]));
    }
  }, [id]);
  useEffect(() => {
    if (getUserResult) {
      setNama(getUserResult.nama);
      setInstansi(getUserResult.unitKerja);
      setAvatar(getUserResult.avatar);
    }
  }, [getUserResult]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (instansi && nama && waktuMasuk) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      if (!edit) {
        catatMasuk();
      } else {
        editDH();
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/daftarhadir' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Catat Masuk</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama</label>
                      <Input type='text' value={nama} name='nama' />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Waktu Masuk</label>
                          <Input
                            type='time'
                            name='jenis'
                            step='1'
                            value={waktuMasuk}
                            onChange={(event) => {
                              setWaktuMasuk(event.target.value);
                              console.log(waktuMasuk);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Waktu Pulang</label>
                          <Input
                            type='time'
                            name='harga'
                            step='1'
                            value={waktuPulang}
                            onChange={(event) => {
                              setWaktuPulang(event.target.value);
                              console.log(waktuPulang);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Status Kehadiran</label>
                          <Input
                            type='select'
                            value={status}
                            name='jenis'
                            onChange={(event) => setStatus(event.target.value)}
                          >
                            <option value={''}>- Pilih- </option>
                            <option value={'Hadir'}>Hadir</option>
                            <option value={'Dinas Luar'}>Dinas Luar</option>
                            <option value={'Izin'}>Izin</option>
                            <option value={'Sakit'}>Sakit</option>
                            <option value={'Tugas Belajar'}>
                              Tugas Belajar
                            </option>
                            <option value={'Izin Belajar'}>Izin Belajar</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <img
                          src={avatar}
                          alt={'avatar'}
                          style={{
                            width: 200,
                            height: 200,
                            borderRadius: 100,
                            borderWidth: 8,
                            borderColor: 'red',
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {!edit ? (
                    <Col>
                      {saveLoading ? (
                        <Button color='primary' type='submit' disabled>
                          <Spinner size='sm' color='light' /> Loading
                        </Button>
                      ) : (
                        <Button type='submit' color='danger' className='ml-2'>
                          <i className='nc-icon nc-basket'></i> Catat
                        </Button>
                      )}
                    </Col>
                  ) : (
                    <Col>
                      {saveLoading ? (
                        <Button color='primary' type='submit' disabled>
                          <Spinner size='sm' color='light' /> Loading
                        </Button>
                      ) : (
                        <Button type='submit' color='danger' className='ml-2'>
                          <i className='nc-icon nc-basket'></i> Edit
                        </Button>
                      )}
                    </Col>
                  )}
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
