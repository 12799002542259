import swal from 'sweetalert';
import FIREBASE from '../config/FIREBASE';
import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';

import { ADMINS_LOGIN, ADMINS_LOGOUT, API_TIMEOUT } from '../utils';
import axios from 'axios';

export const LOGIN_USER = 'LOGIN_USER';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const loginUser = (email, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);

    FIREBASE.auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        FIREBASE.database()
          .ref(`users/${res.user.uid}`)
          .once('value')
          .then((resDB) => {
            // Signed in
            if (resDB.val()) {
              if (resDB.val().status === 'admin') {
                axios({
                  method: 'post',
                  url: ADMINS_LOGIN,
                  timeout: API_TIMEOUT,
                  data: { username: email, password: password },
                })
                  .then((response) => {
                    if (response.status !== 200) {
                      // ERROR
                      dispatchError(dispatch, LOGIN_USER, 'error');
                    } else {
                      //BERHASIL
                      if (response.data.success == 0) {
                        swal('Failed', 'Anda bukan admin cpanel', 'error');
                        dispatchError(dispatch, LOGIN_USER, 'error');
                      } else {
                        dispatchSuccess(
                          dispatch,
                          LOGIN_USER,
                          response.data.data ? response.data.data : []
                        );

                        window.localStorage.setItem(
                          'user',
                          JSON.stringify(resDB.val())
                        );
                      }
                    }
                  })
                  .catch((error) => {
                    // ERROR
                    dispatchError(dispatch, LOGIN_USER, error);
                    console.log(error);
                    swal('Failed', 'Terjadi kesalahan', 'error');
                  });

                //dispatchSuccess(dispatch, LOGIN_USER, resDB.val());
              } else {
                dispatchError(dispatch, LOGIN_USER, 'Anda Bukan Admin');
                swal('Failed', 'Anda Bukan Admin', 'error');
              }
            }
          })
          .catch((error) => {
            dispatchError(dispatch, LOGIN_USER, error);
            swal('Failed', error, 'error');
          });
      })
      .catch((error) => {
        var errorMessage = error.message;

        dispatchError(dispatch, LOGIN_USER, errorMessage);
        swal('Failed', errorMessage, 'error');
      });
  };
};

export const loginUser2 = (email, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);
    axios({
      method: 'post',
      url: ADMINS_LOGIN,
      timeout: API_TIMEOUT,
      data: { username: email, password: password },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, LOGIN_USER, 'error');
        } else {
          //BERHASIL
          if (response.data.success == 0) {
            swal('Failed', 'email dan password anda salah', 'error');
            dispatchError(dispatch, LOGIN_USER, 'error');
          } else {
            dispatchSuccess(
              dispatch,
              LOGIN_USER,
              response.data.data ? response.data.data : []
            );
          }
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, LOGIN_USER, error);
        swal('Failed', 'Anda Bukan Admin', 'error');
      });
  };
};

export const checkLogin = (history) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CHECK_LOGIN);

    if (window.localStorage.getItem('user')) {
      const user = JSON.parse(window.localStorage.getItem('user'));

      FIREBASE.database()
        .ref(`users/${user.uid}`)
        .once('value')
        .then((resDB) => {
          if (resDB.val()) {
            if (resDB.val().status === 'admin') {
              dispatchSuccess(dispatch, CHECK_LOGIN, resDB.val());
            } else {
              dispatchError(dispatch, CHECK_LOGIN, 'Anda Bukan Admin');
              history.push({ pathname: '/login' });
            }
          } else {
            dispatchError(dispatch, CHECK_LOGIN, 'Anda Bukan Admin');
            history.push({ pathname: '/login' });
          }
        })
        .catch((error) => {
          dispatchError(dispatch, CHECK_LOGIN, error);
          history.push({ pathname: '/login' });
        });
    } else {
      dispatchError(dispatch, CHECK_LOGIN, 'Belum Login');
      history.push({ pathname: '/login' });
    }
  };
};

export const logOutUser = (history) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGOUT);

    axios({
      method: 'delete',
      url: ADMINS_LOGOUT,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, LOGOUT, 'error');
          history.push('/');
        } else {
          //BERHASIL
          if (response.data.success == 0) {
            swal('Failed', 'email dan password anda salah', 'error');
            dispatchError(dispatch, LOGOUT, 'error');
            history.push('/');
          } else {
            FIREBASE.auth()
              .signOut()
              .then((res) => {
                window.localStorage.removeItem('user');
                dispatchSuccess(dispatch, LOGOUT, res);
                history.push({ pathname: '/login' });
              })
              .catch((error) => {
                dispatchError(dispatch, LOGOUT, error.message);
                swal('Failed!', error.message, 'error');
              });
          }
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, LOGOUT, error);
        history.push('/');
        swal('Failed', 'Anda Bukan Admin', 'error');
      });

    // FIREBASE.auth()
    //   .signOut()
    //   .then((res) => {
    //     window.localStorage.removeItem('user');
    //     dispatchSuccess(dispatch, LOGOUT, res);
    //     history.push({ pathname: '/login' });
    //   })
    //   .catch((error) => {
    //     dispatchError(dispatch, LOGOUT, error.message);
    //     swal('Failed!', error.message, 'error');
    //   });
  };
};

export const updateProfile = (data) => {
  return (dispatch) => {
    //LOADING
    let dataBaru = {};
    if (data.statusRegister == 'ASN') {
      dataBaru = {
        uid: data.uid,
        nama: data.nama,
        alamat: data.alamat,
        nohp: data.nohp,
        kecamatan: data.kecamatan,
        desa: data.desa,
        email: data.email,
        pangkat: data.pangkat,
        unitKerja: data.unitKerja,
        nip: data.nip,
        status: data.status,
        avatar: data.avatar,
        domisili: data.domisili,
        statusRegister: data.statusRegister,
        provinsi: data.provinsi,
        kota: data.kota,
        dob: data.dob,
        date: data.date,
        gender: data.gender,
        fcmToken: data.fcmToken,
        token: data.token,
        nik: data.nik,
        namaPengguna: data.namaPengguna,
      };
    } else {
      dataBaru = {
        uid: data.uid,
        nama: data.nama,
        alamat: data.alamat,
        nohp: data.nohp,
        kecamatan: data.kecamatan,
        desa: data.desa,
        email: data.email,
        status: data.status,
        avatar: data.avatar,
        domisili: data.domisili,
        statusRegister: data.statusRegister,
        provinsi: data.provinsi,
        kota: data.kota,
        dob: data.dob,
        date: data.date,
        gender: data.gender,
        fcmToken: data.fcmToken,
        token: data.token,
        nik: data.nik,
        namaPengguna: data.namaPengguna,
      };
    }

    dispatchLoading(dispatch, UPDATE_PROFILE);

    FIREBASE.database()
      .ref('users/' + dataBaru.uid)
      .update(dataBaru)
      .then((response) => {
        //SUKSES
        dispatchSuccess(dispatch, UPDATE_PROFILE, response ? response : []);
        //simpan ke local storage (Async)
        swal('Success!', 'Data Profile berhasil diedit', 'success');
      })
      .catch((error) => {
        //ERROR;
        dispatchError(dispatch, UPDATE_PROFILE, error.message);

        console.log(error);

        swal('Failed!', error.message, 'error');
      });
  };
};
