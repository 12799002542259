import { GET_LIST_DESTINASI } from '../../actions/DestinasiAction';

const initialState = {
  getListDestinasiLoading: false,
  getListDestinasiResult: false,
  getListDestinasiError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_DESTINASI:
      return {
        ...state,
        getListDestinasiLoading: action.payload.loading,
        getListDestinasiResult: action.payload.data,
        getListDestinasiError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
