import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';
import { GET_ANGKUTSAMPAH_ALL, API_TIMEOUT } from '../utils/constant';

import axios from 'axios';

export const GET_LIST_ANGKUTSAMPAH = 'GET_LIST_ANGKUTSAMPAH';

export const getAngkutSampah = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_ANGKUTSAMPAH);

    axios({
      method: 'get',
      url: GET_ANGKUTSAMPAH_ALL,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_ANGKUTSAMPAH, 'error');
        } else {
          //BERHASIL

          dispatchSuccess(
            dispatch,
            GET_LIST_ANGKUTSAMPAH,
            response.data.data ? response.data.data : []
          );
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_ANGKUTSAMPAH, error);
        alert(error);
      });
  };
};
