import { GET_LIST_SERVICE } from '../../actions/ServiceAction';

const initialState = {
  getServiceLoading: false,
  getServiceResult: false,
  getServiceError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SERVICE:
      return {
        ...state,
        getServiceLoading: action.payload.loading,
        getServiceResult: action.payload.data,
        getServiceError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
