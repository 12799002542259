import FIREBASE from '../config/FIREBASE';
import { dispatchError, dispatchLoading, dispatchSuccess } from '../utils';
import {
  API_DAFTARHADIR,
  API_TIMEOUT,
  API_DAFTARHADIR_CEK,
} from '../utils/constant';

import axios from 'axios';
export const CATAT_DH_MASUK = 'CATAT_DH_MASUK';
export const CATAT_DH_PULANG = 'CATAT_DH_PULANG';
export const GET_LIST_DH = 'GET_LIST_DH';
export const GET_LIST_DH_BULANAN = 'GET_LIST_DH_BULANAN';
export const DELETE_DH = 'DELETE_DH';
export const CLEAR_DH = 'CLEAR_DH';
export const CLEAR_CEK_CATATAN = 'CLEAR_CEK_CATATAN';
export const CEK_CATATAN_DH = 'CEK_CATATAN_DH';
export const CLEAR_CATAT_PULANG = 'CLEAR_CATAT_PULANG';

export const clearDH = () => {
  return (dispatch) => {
    dispatchSuccess(dispatch, CLEAR_DH, '');
  };
};

export const clearCekCatatan = () => {
  return (dispatch) => {
    dispatchSuccess(dispatch, CLEAR_CEK_CATATAN, '');
  };
};

export const catatMasuk = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CATAT_DH_MASUK);

    axios({
      method: 'post',
      url: API_DAFTARHADIR,
      timeout: API_TIMEOUT,
      data: {
        tahun: data.tahun,
        bulan: data.bulan,
        tanggal: data.tanggal,
        nama: data.nama,
        opd: data.opd,
        waktuMasuk: data.waktuMasuk,
        waktuPulang: data.waktuPulang,
        status: data.status,
        koordinatMasuk: data.koordinatMasuk,
        koordinatPulang: data.koordinatPulang,
        workingHours: data.workingHours,
        token: data.token,
        idUser: data.idUser,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          alert('error');
          dispatchError(dispatch, CATAT_DH_MASUK, 'error');
        } else {
          //BERHASIL
          var dataKirim = {
            id: response.data.data.insertId,
            status: data.status,
          };
          dispatchSuccess(dispatch, CATAT_DH_MASUK, response ? dataKirim : []);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
        dispatchError(dispatch, CATAT_DH_MASUK, 'error');

        //alert(error);
      });
  };
};

export const cekCatatanDH = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CEK_CATATAN_DH);

    axios({
      method: 'post',
      url: API_DAFTARHADIR_CEK,
      timeout: API_TIMEOUT,
      data: {
        tanggal: data.tanggal,
        nama: data.nama,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, CEK_CATATAN_DH, 'error');
        } else {
          //BERHASIL
          const dataku = response ? response.data.data[0] : [];
          dispatchSuccess(dispatch, CEK_CATATAN_DH, dataku);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, CEK_CATATAN_DH, 'error');
        // alert(error);
      });
  };
};

export const cekCatatanDH2 = (data) => {
  axios({
    method: 'post',
    url: API_DAFTARHADIR_CEK,
    timeout: API_TIMEOUT,
    data: {
      tanggal: data.tanggal,
      nama: data.nama,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        // ERROR
        return 'error';
      } else {
        //BERHASIL
        const dataku = response ? response : [];

        return dataku;
      }
    })
    .catch((error) => {
      // ERROR
      return error;
      //alert(error);
    });
};
export const catatPulang = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, CATAT_DH_PULANG);

    axios({
      method: 'post',
      url: API_DAFTARHADIR + '/' + 'pulang',
      timeout: API_TIMEOUT,
      data: {
        waktuPulang: data.waktuPulang,
        koordinatPulang: data.koordinatPulang,
        workingHours: data.workingHours,
        token: data.token,
        id: data.id,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, CATAT_DH_PULANG, 'error');
        } else {
          //BERHASIL
          const dataku = response ? response.data : [];
          dispatchSuccess(dispatch, CATAT_DH_PULANG, dataku);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, CATAT_DH_PULANG, 'error');
        alert(error);
      });
  };
};

export const clearCatatPulang = () => {
  return (dispatch) => {
    dispatchSuccess(dispatch, CLEAR_CATAT_PULANG, '');
  };
};

export const getListDaftarHadir = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    axios({
      method: 'post',
      url: API_DAFTARHADIR + '/getdh',
      timeout: API_TIMEOUT,
      data: {
        tanggal: data.tanggal,
        opd: unitKerja,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH, 'error');
        } else {
          //BERHASIL
          const dataDH = response.data.data;
          dispatchSuccess(dispatch, GET_LIST_DH, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH, 'error');
        // alert(error);
      });
  };
};

export const getListDaftarHadirInstansi = (data) => {
  const unitKerja = data.unitKerja;

  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH);

    FIREBASE.database()
      .ref('users')
      .orderByChild('unitKerja')
      .equalTo(unitKerja)
      .once('value', (querySnapshot) => {
        let dataKu = querySnapshot.val();
        dataKu = { ...dataKu };

        if (dataKu) {
          axios({
            method: 'post',
            url: API_DAFTARHADIR + '/getdh',
            timeout: API_TIMEOUT,
            data: {
              tanggal: data.tanggal,
              opd: unitKerja,
            },
          })
            .then((response) => {
              if (response.status !== 200) {
                // ERROR
                dispatchError(dispatch, GET_LIST_DH, 'error');
              } else {
                //BERHASIL
                const dataDH = response.data.data;

                const dataKirim = Object.keys(dataKu).map((keys) => {
                  let dataFilter = dataDH.filter((filter) => {
                    return dataKu[keys].nama === filter.nama;
                  });

                  const dataSementara = {};
                  dataSementara.nama = dataKu[keys].nama;
                  dataSementara.nip = dataKu[keys].nip;
                  dataSementara.pangkat = dataKu[keys].pangkat;
                  dataSementara.uid = dataKu[keys].uid;
                  dataSementara.avatar = dataKu[keys].avatar
                    ? dataKu[keys].avatar
                    : null;
                  dataSementara.id = dataFilter[0] ? dataFilter[0].id : '';
                  dataSementara.waktuMasuk = dataFilter[0]
                    ? dataFilter[0].waktuMasuk
                    : '';
                  dataSementara.waktuPulang = dataFilter[0]
                    ? dataFilter[0].waktuPulang
                    : '';
                  dataSementara.workingHours = dataFilter[0]
                    ? dataFilter[0].workingHours
                    : '';
                  dataSementara.status = dataFilter[0]
                    ? dataFilter[0].status
                    : '';

                  return dataSementara;
                });

                dispatchSuccess(dispatch, GET_LIST_DH, dataKirim);
              }
            })
            .catch((error) => {
              // ERROR
              dispatchError(dispatch, GET_LIST_DH, 'error');
              // alert(error);
            });
        }
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LIST_DH, 'error');
        //alert(error);
      });
  };
};

export const getListDaftarHadirBulanan = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LIST_DH_BULANAN);

    axios({
      method: 'post',
      url: API_DAFTARHADIR + '/getdh/bulanan',
      timeout: API_TIMEOUT,
      data: {
        token: data.token,
        bulan: data.bulan,
        tahun: data.tahun,
        idUser: data.idUser,
      },
      headers: {
        Authorization: `Bearer ${data.accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          dispatchError(dispatch, GET_LIST_DH_BULANAN, 'error');
        } else {
          //BERHASIL
          const dataDH = response.data.data;

          dispatchSuccess(dispatch, GET_LIST_DH_BULANAN, dataDH);
        }
      })
      .catch((error) => {
        // ERROR
        dispatchError(dispatch, GET_LIST_DH_BULANAN, 'error');
        alert(error);
      });
  };
};
