import {
  CATAT_DH_MASUK,
  CEK_CATATAN_DH,
  CATAT_DH_PULANG,
  CLEAR_CATAT_PULANG,
  CLEAR_CEK_CATATAN,
  GET_LIST_DH,
  GET_LIST_DH_BULANAN,
} from '../../actions/DaftarHadirAction';

const initialState = {
  catatMasukLoading: false,
  catatMasukResult: false,
  catatMasukError: false,

  cekCatatanLoading: false,
  cekCatatanResult: false,
  cekCatatanError: false,

  catatPulangLoading: false,
  catatPulangResult: false,
  catatPulangError: false,

  getListDHLoading: false,
  getListDHResult: false,
  getListDHError: false,

  getListDHBulananLoading: false,
  getListDHBulananResult: false,
  getListDHBulananError: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CATAT_DH_MASUK:
      return {
        ...state,
        catatMasukLoading: action.payload.loading,
        catatMasukResult: action.payload.data,
        catatMasukError: action.payload.errorMessage,
      };

    case CEK_CATATAN_DH:
      return {
        ...state,
        cekCatatanLoading: action.payload.loading,
        cekCatatanResult: action.payload.data,
        cekCatatanError: action.payload.errorMessage,
      };

    case CATAT_DH_PULANG:
      return {
        ...state,
        catatPulangLoading: action.payload.loading,
        catatPulangResult: action.payload.data,
        catatPulangError: action.payload.errorMessage,
      };

    case CLEAR_CATAT_PULANG:
      return {
        ...state,
        catatPulangLoading: false,
        catatPulangResult: false,
        catatPulangError: false,
      };
    case CLEAR_CEK_CATATAN:
      return {
        ...state,
        cekCatatanLoading: false,
        cekCatatanResult: false,
        cekCatatanError: false,
      };

    case GET_LIST_DH:
      return {
        ...state,
        getListDHLoading: action.payload.loading,
        getListDHResult: action.payload.data,
        getListDHError: action.payload.errorMessage,
      };

    case GET_LIST_DH_BULANAN:
      return {
        ...state,
        getListDHBulananLoading: action.payload.loading,
        getListDHBulananResult: action.payload.data,
        getListDHBulananError: action.payload.errorMessage,
      };

    default:
      return state;
  }
}
