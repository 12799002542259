import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import axios from 'axios';
import { POST_TOKO } from 'utils';
import DefaultImage from '../../assets/img/default-image.jpg';

export default function TambahToko() {
  const history = useHistory();
  const { id } = useParams();

  const [toko, setToko] = useState('');
  const [pemilik, setPemilik] = useState('');
  const [noHp, setNoHp] = useState('');

  const [alamat, setAlamat] = useState('');
  const [bintang, setBintang] = useState('');

  const [foto, setFoto] = useState('');

  const [idAdmin, setIdAdmin] = useState('');

  const [urlFile1, setUrlFile1] = useState(DefaultImage);
  const [saveLoading, setSaveLoading] = useState(false);

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFoto(e.target.files[0]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (toko && pemilik && alamat && noHp && foto) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      formData.append('photo', foto);

      formData.append('toko', toko);
      formData.append('pemilik', pemilik);
      formData.append('noHp', noHp);
      formData.append('alamat', alamat);
      formData.append('bintang', bintang);
      formData.append('idAdmin', id);

      const response = await axios.post(POST_TOKO, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Toko berhasil ditambahkan', 'success');
        history.push('/admin/toko');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/toko' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Toko</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Toko (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama Toko</label>
                      <Input
                        type='text'
                        value={toko}
                        name='nama'
                        onChange={(event) => setToko(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Nomor HP</label>
                          <Input
                            type='text'
                            value={noHp}
                            name='jenis'
                            onChange={(event) => setNoHp(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Pemilik</label>
                          <Input
                            type='text'
                            value={pemilik}
                            name='harga'
                            onChange={(event) => setPemilik(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Bintang</label>
                          <Input
                            type='text'
                            style={{
                              resize: 'vertical',
                            }}
                            value={bintang}
                            name='text'
                            id='exampleText'
                            onChange={(event) => setBintang(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Alamat Toko</label>
                          <Input
                            type='text'
                            value={alamat}
                            name='jenis'
                            onChange={(event) => setAlamat(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
