import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { getService } from '../../actions/ServiceAction';
import { DELETE_SERVICE, GET_TOKEN } from 'utils';

import swal from 'sweetalert';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { logOutUser } from 'actions/AuthAction';

export default function ListServices() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getServiceError, getServiceLoading, getServiceResult } = useSelector(
    (state) => state.ServiceReducer
  );

  // auth

  const [token, setToken] = useState('');
  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');
  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setAccessToken(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        // history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  useEffect(() => {
    dispatch(getService());
    refreshToken();
  }, []);

  const removeData = async (id) => {
    try {
      await axios.delete(DELETE_SERVICE + `${id}`, {
        credentials: 'include',
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      swal('Sukses!', 'Layanan berhasil dihapus', 'success');
      history.push('/admin');
      history.push('/admin/services');
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>List Layanan</CardTitle>
              <Link
                to={'/admin/services/tambah/' + idAdmin}
                className='btn btn-primary float-right'
              >
                Tambah Layanan
              </Link>
            </CardHeader>
            <CardBody style={{ overflowX: 'auto' }}>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Id</th>
                    <th>Gambar</th>
                    <th>Nama</th>
                    <th>Page</th>
                    <th>Icon</th>
                    <th>Prioritas</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {getServiceResult ? (
                    Object.keys(getServiceResult).map((key) => (
                      <tr key={key}>
                        <td>{getServiceResult[key].id}</td>
                        <td>
                          <img
                            src={getServiceResult[key].gambar.split(',')[0]}
                            width='40'
                            alt={getServiceResult[key].nama}
                          />
                        </td>
                        <td>{getServiceResult[key].nama}</td>
                        <td>{getServiceResult[key].page}</td>
                        <td>{getServiceResult[key].icon} </td>
                        <td>{getServiceResult[key].prioritas} </td>
                        <td>
                          <Link
                            className='btn btn-warning'
                            to={
                              '/admin/services/edit/' + getServiceResult[key].id
                            }
                          >
                            <i className='nc-icon nc-ruler-pencil'></i> Edit
                          </Link>
                        </td>
                        <td>
                          <Button
                            color='danger'
                            className='ml-2'
                            onClick={() => removeData(getServiceResult[key].id)}
                          >
                            <i className='nc-icon nc-basket'></i> Hapus
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : getServiceLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : getServiceError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getServiceError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
