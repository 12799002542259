import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Spinner,
  Button,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import { getPasarTernak } from 'actions/PasarTernakAction';
import { DELETE_PASARTERNAK, GET_TOKEN } from 'utils';

import swal from 'sweetalert';
import axios from 'axios';
import { logOutUser } from 'actions/AuthAction';
import jwt_decode from 'jwt-decode';

export default function ListPasarTernak() {
  const dispatch = useDispatch();
  const history = useHistory();

  // auth

  const [token, setToken] = useState('');

  const [nama, setNama] = useState('');
  const [username, setUsername] = useState('');
  const [akses, setAkses] = useState('');
  const [expire, setExpire] = useState('');
  const [idAdmin, setIdAdmin] = useState('1');

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token');
      setToken(response.data);
      const decoded = jwt_decode(response.data.accessToken);
      setNama(decoded.nama);
      setUsername(decoded.username);
      setAccessToken(response.data.accessToken);
      setAkses(decoded.akses.split(','));
      setExpire(decoded.exp);
      setIdAdmin(decoded.idAdmin);
    } catch (error) {
      if (error.response) {
        dispatch(logOutUser(history));
        history.push('/');
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(GET_TOKEN + '/token');
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
        const decoded = jwt_decode(response.data.accessToken);
        setNama(decoded.nama);
        setUsername(decoded.username);
        setAkses(decoded.akses);
        setIdAdmin(decoded.idAdmin);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  //auth

  const {
    getListPasarTernakError,
    getListPasarTernakLoading,
    getListPasarTernakResult,
  } = useSelector((state) => state.PasarTernakReducer);

  useEffect(() => {
    dispatch(getPasarTernak());
    refreshToken();
  }, []);

  const removeData = async (id) => {
    try {
      await axios.delete(DELETE_PASARTERNAK + `${id}`, {
        credential: 'include',
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      swal('Sukses!', 'Laporan berhasil dihapus', 'success');
      history.push('/admin');
      history.push('/admin/pasarternak');
    } catch (error) {
      swal('Failed!', 'Forbiden', 'error');
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col md='12'>
          <Card>
            <CardHeader>
              <CardTitle tag='h4'>Postingan Pasar Ternak</CardTitle>
            </CardHeader>
            <CardBody>
              <Table>
                <thead className='text-primary'>
                  <tr>
                    <th>Id</th>
                    <th>Foto</th>
                    <th>Kategori</th>
                    <th>Umur</th>
                    <th>Status Verifikasi</th>

                    <th>Desa</th>
                    <th>Phone</th>

                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {getListPasarTernakResult ? (
                    Object.keys(getListPasarTernakResult).map((key) => (
                      <tr key={key}>
                        <td>{getListPasarTernakResult[key].id}</td>
                        <td>
                          <img
                            src={
                              getListPasarTernakResult[key].foto.split(',')[0]
                            }
                            width='100'
                            alt={getListPasarTernakResult[key].kategori}
                          />
                        </td>
                        <td>{getListPasarTernakResult[key].kategori}</td>
                        <td>{getListPasarTernakResult[key].umur}</td>
                        <td>
                          {getListPasarTernakResult[key].statusVerifikasi}{' '}
                        </td>

                        <td>{getListPasarTernakResult[key].desa} </td>
                        <td>{getListPasarTernakResult[key].nohp} </td>

                        <td>{getListPasarTernakResult[key].status} </td>
                        <td>
                          <Link
                            className='btn btn-warning'
                            to={
                              '/admin/pasarternak/edit/' +
                              getListPasarTernakResult[key].id +
                              ',' +
                              idAdmin
                            }
                          >
                            <i className='nc-icon nc-ruler-pencil'></i> TL
                          </Link>
                        </td>
                        <td>
                          <Button
                            color='danger'
                            className='ml-2'
                            onClick={() =>
                              removeData(getListPasarTernakResult[key].id)
                            }
                          >
                            <i className='nc-icon nc-basket'></i> Hapus
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : getListPasarTernakLoading ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        <Spinner color='primary' />
                      </td>
                    </tr>
                  ) : getListPasarTernakError ? (
                    <tr>
                      <td colSpan='6' align='center'>
                        {getListPasarTernakError}
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan='6' align='center'>
                        Data Kosong
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
