import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import swal from 'sweetalert';

import { GET_SERVICE_DETAIL } from 'utils';
import axios from 'axios';
import { UPDATE_SERVICE, GET_TOKEN } from 'utils';

export default function EditServices() {
  const { id } = useParams();

  const history = useHistory();

  const [nama, setNama] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [page, setPage] = useState('');

  const [icon, setIcon] = useState('');
  const [gambar, setGambar] = useState('');

  const [prioritas, setPrioritas] = useState('');
  const [akses, setAkses] = useState('');

  const [jenis, setJenis] = useState('');
  const [link, setLink] = useState('');
  const [login, setLogin] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);

  const [urlFile1, setUrlFile1] = useState('');

  // auth

  const [accessToken, setAccessToken] = useState('');

  const refreshToken = async () => {
    try {
      const response = await axios.get(GET_TOKEN + '/token', {
        credentials: 'include',
      });
      setAccessToken(response.data.accessToken);
    } catch (error) {
      if (error.response) {
        // history.push('/admin/services');
      }
    }
  };
  //auth

  useEffect(() => {
    refreshToken();
    getDetailService();
  }, [id]);

  const getDetailService = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_SERVICE_DETAIL + `${id}`);

      console.log(GET_SERVICE_DETAIL + `${id}`);
      data1 = response.data.data[0];

      if (data1) {
        setUrlFile1(data1.gambar.split(',')[0]);

        setNama(data1.nama);
        setKeterangan(data1.keterangan);
        setPage(data1.page);
        setIcon(data1.icon);
        setGambar(data1.gambar);
        setPrioritas(data1.prioritas);
        setAkses(data1.akses);
        setJenis(data1.jenis);
        setLink(data1.link);
        setLogin(data1.login);
      } else {
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setGambar(e.target.files[0]);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaveLoading(true);

    if (nama && keterangan && page && icon && gambar && prioritas && akses) {
      //action
      // this.props.dispatch(updateJersey(this.state));

      const formData = new FormData();
      formData.append('photo', gambar);
      formData.append('nama', nama);
      formData.append('keterangan', keterangan);
      formData.append('page', page);
      formData.append('icon', icon);
      formData.append('prioritas', prioritas);
      formData.append('akses', akses);
      formData.append('jenis', jenis);
      formData.append('link', link);
      formData.append('login', login);

      const response = await axios.patch(UPDATE_SERVICE + `${id}`, formData, {
        credentials: 'include',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data.success === 1) {
        swal('Success', 'Layanan berhasil diedit', 'success');
        history.push('/admin/services');
      }
    } else {
      swal('Failed', 'Maaf semua form wajib diisi', 'error');
    }
    setSaveLoading(false);
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Link to='/admin/services' className='btn btn-primary'>
            Kembali
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardHeader tag='h4'>Edit Layanan</CardHeader>
            <CardBody>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col>
                        <img
                          src={urlFile1}
                          width='300'
                          alt='Foto Berita (satu)'
                        />
                        <FormGroup>
                          <label>Foto Toko (satu)</label>
                          <Input
                            type='file'
                            name='image1'
                            accept={'image/*'}
                            onChange={(event) => onChange1(event, 'imageToDB1')}
                          />
                        </FormGroup>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <label>Nama Layanan</label>
                      <Input
                        type='text'
                        value={nama}
                        name='nama'
                        onChange={(event) => setNama(event.target.value)}
                      />
                    </FormGroup>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Page</label>
                          <Input
                            type='text'
                            value={page}
                            name='jenis'
                            onChange={(event) => setPage(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Icon</label>
                          <Input
                            type='text'
                            value={icon}
                            name='harga'
                            onChange={(event) => setIcon(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Prioritas</label>
                          <Input
                            type='number'
                            style={{
                              resize: 'vertical',
                            }}
                            value={prioritas}
                            name='text'
                            id='exampleText'
                            onChange={(event) =>
                              setPrioritas(event.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Akses</label>
                          <Input
                            type='text'
                            style={{
                              resize: 'vertical',
                            }}
                            value={akses}
                            name='text'
                            id='exampleText'
                            onChange={(event) => setAkses(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Jenis</label>
                          <Input
                            type='select'
                            style={{
                              resize: 'vertical',
                            }}
                            value={jenis}
                            name='text'
                            id='exampleText'
                            onChange={(event) => setJenis(event.target.value)}
                          >
                            <option>-Pilih-</option>
                            <option>Mobile</option>
                            <option>WebApp</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label>Link</label>
                          <Input
                            type='text'
                            style={{
                              resize: 'vertical',
                            }}
                            value={link}
                            name='text'
                            id='exampleText'
                            onChange={(event) => setLink(event.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label>Akses harus Login ?</label>
                          <Input
                            type='select'
                            style={{
                              resize: 'vertical',
                            }}
                            value={login}
                            name='text'
                            id='exampleText'
                            onChange={(event) => setLogin(event.target.value)}
                          >
                            <option value={''}>-Pilih-</option>
                            <option value={'Ya'}>Ya</option>
                            <option value={'Tidak'}>Tidak</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <label>Keterangan</label>
                      <Input
                        type='textarea'
                        style={{
                          resize: 'vertical',
                          height: '400px',
                        }}
                        value={keterangan}
                        name='text'
                        id='exampleText'
                        onChange={(event) => setKeterangan(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {saveLoading ? (
                      <Button color='primary' type='submit' disabled>
                        <Spinner size='sm' color='light' /> Loading
                      </Button>
                    ) : (
                      <Button type='submit' color='danger' className='ml-2'>
                        <i className='nc-icon nc-basket'></i> Simpan
                      </Button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
